import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/cartContext';
import ProductCounter from "../products/productCounter";
import NoteModal from '../cart/NoteModal';
import { notification } from 'antd'; 

const Cart = () => {
    const { cart, setCart, removeItemFromCart, clearCart } = useCart();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const calculateTotal = () => {
        return cart.reduce((total, item) => total + item.price * item.quantity, 0);
    };

    useEffect(() => {
        console.log("Cart updated:", cart);
        console.log("Cart Total:", calculateTotal());
    }, [cart]);

    const handleOpenModal = (product) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSaveNote = (productId, note) => {
        const updatedCart = cart.map(item =>
            item.id === productId ? { ...item, notes: note } : item
        );
        setCart(updatedCart);
        setShowModal(false);
    };

    const handlePlaceOrder = () => {
        // Verificar si algún producto en el carrito excede el stock disponible
        const productExceedingStock = cart.find(item => item.quantity > item.pieces);
    
        if (productExceedingStock) {
            notification.error({
                message: 'Límite de stock alcanzado',
                description: `El producto "${productExceedingStock.name}" ha alcanzado el límite de stock disponible (${productExceedingStock.pieces} unidades).`,
                duration: 3,
            });
            return;
        }
    
        // Mostrar el total del carrito antes de continuar
        const cartTotal = calculateTotal();
        console.log('Cart Total:', cartTotal);
    
        // Navegar a la página de selección de método de envío
        navigate('/shipping-method', { state: { cartTotal } });
    };

    return (
        <section>
            <div className="container">
                <div className='cart'>
                    {cart.length === 0 ? (
                        <h1 className='f-h jc-c a-c h-90 mb-less-100'>Tu carrito está vacío.</h1>
                    ) : (
                        <>
                            {cart.map(item => (
                                <div className='cart-card mtb-20' key={item.id}>
                                    <div className='cart-card-50 overflow-hidden br-16 f-v jc-sb'>
                                        <div className='cart-card-img'>
                                            <img src={item.image_url} alt={item.name} />
                                        </div>
                                <button className="trash" onClick={() => removeItemFromCart(item.id)}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.3375 3.30001H15.45V2.66251C15.45 1.53751 14.55 0.637512 13.425 0.637512H10.5375C9.41251 0.637512 8.51251 1.53751 8.51251 2.66251V3.30001H5.62501C4.53751 3.30001 3.63751 4.20001 3.63751 5.28751V6.41251C3.63751 7.23751 4.12501 7.91251 4.83751 8.21251L5.43751 21.1875C5.51251 22.425 6.48751 23.3625 7.72501 23.3625H16.1625C17.4 23.3625 18.4125 22.3875 18.45 21.1875L19.125 8.17501C19.8375 7.87501 20.325 7.16251 20.325 6.37501V5.25001C20.325 4.20001 19.425 3.30001 18.3375 3.30001ZM10.2375 2.66251C10.2375 2.47501 10.3875 2.32501 10.575 2.32501H13.4625C13.65 2.32501 13.8 2.47501 13.8 2.66251V3.30001H10.275V2.66251H10.2375ZM5.36251 5.28751C5.36251 5.13751 5.47501 4.98751 5.66251 4.98751H18.3375C18.4875 4.98751 18.6375 5.10001 18.6375 5.28751V6.41251C18.6375 6.56251 18.525 6.71251 18.3375 6.71251H5.66251C5.51251 6.71251 5.36251 6.60001 5.36251 6.41251V5.28751ZM16.2 21.675H7.80001C7.46251 21.675 7.20001 21.4125 7.20001 21.1125L6.60001 8.40001H17.4375L16.8375 21.1125C16.8 21.4125 16.5375 21.675 16.2 21.675Z" fill="#AA0000"/>
                                    <path d="M12 12.15C11.55 12.15 11.1375 12.525 11.1375 13.0125V17.775C11.1375 18.225 11.5125 18.6375 12 18.6375C12.45 18.6375 12.8625 18.2625 12.8625 17.775V13.0125C12.8625 12.525 12.45 12.15 12 12.15Z" fill="#AA0000"/>
                                    <path d="M15 12.9C14.5125 12.8625 14.1375 13.2 14.1 13.6875L13.875 16.9875C13.8375 17.4375 14.175 17.85 14.6625 17.8875C14.7 17.8875 14.7 17.8875 14.7375 17.8875C15.1875 17.8875 15.5625 17.55 15.5625 17.1L15.7875 13.8C15.7875 13.3125 15.45 12.9375 15 12.9Z" fill="#AA0000"/>
                                    <path d="M8.96245 12.9C8.51245 12.9375 8.13745 13.35 8.17495 13.8L8.43745 17.1C8.47495 17.55 8.84995 17.8875 9.26245 17.8875C9.29995 17.8875 9.29995 17.8875 9.33745 17.8875C9.78745 17.85 10.1625 17.4375 10.125 16.9875L9.86245 13.6875C9.86245 13.2 9.44995 12.8625 8.96245 12.9Z" fill="#AA0000"/>
                                </svg>
                                </button>
                            </div>
                            <div className='cart-card-50'>
                                <h3 className='mb-15'>{item.name}</h3>
                                <p className='mtb-16'>Costo Unitario: $ {item.price}</p>
                                <button className='note' onClick={() => handleOpenModal(item)}>
                                    {item.notes ? 'Ver nota' : 'Agregar nota'}
                                </button>
                                <ProductCounter product={item} />
                                <p className='final-cost'>Costo Total: ${item.price * item.quantity}</p>
                            </div>
                        </div>
                    ))}
                    <div className="cart-total mb-16 ">
                        <div className='bold'>
                            <p>Subtotal</p>
                            <p>${calculateTotal()}</p>
                        </div>
                    </div>
                    <button className='btn btn-pedido' onClick={handlePlaceOrder}>
                        Realizar Pedido
                    </button>
                </>
            )}
            {showModal && (
                <NoteModal
                    product={selectedProduct}
                    onSave={handleSaveNote}
                    onClose={handleCloseModal}
                />
            )}
        </div>
        </div>
    </section>
    );
};

export default Cart;