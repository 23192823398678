import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';

const RequestPasswordReset = () => {
  const [securityAnswer, setSecurityAnswer] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const { fetchUserByEmailOrPhone, verifySecurityAnswer, resetPassword } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [availableQuestions, setAvailableQuestions] = useState([
    "¿Cómo se llama tu primera mascota?",
    "¿En qué ciudad naciste?",
    "¿Cuál es el nombre de tu abuelo/a?",
    "¿Cuál era el nombre de tu primera escuela?",
    "¿Cómo se llamaba tu mejor amigo/a de la infancia?"
  ]); // Lista de preguntas de seguridad

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const user = queryParams.get('user');
    if (user) {
      setEmailOrPhone(user);
      fetchSecurityQuestion(user);
    }
  }, [location.search]);

  const fetchSecurityQuestion = async (user) => {
    try {
      const profile = await fetchUserByEmailOrPhone(user);
      setSecurityQuestion(profile.security_question);
    } catch (error) {
      console.log('Error al buscar usuario: ' + error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      // Verifica la respuesta de seguridad
      await verifySecurityAnswer(emailOrPhone, securityAnswer);

      // Restablece la contraseña
      await resetPassword(emailOrPhone, newPassword);
      console.log('¡Contraseña restablecida correctamente!');

      // Redirigir al login después de cambiar la contraseña
      navigate('/login');
    } catch (error) {
      console.log('Error al restablecer la contraseña: ' + error.message);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <h1 className='txt-align-center mtb-16'>Restablecer Contraseña</h1>
          <form onSubmit={handleResetPassword}>
            <p className='mb-5'>Selecciona tu pregunta de seguridad:</p>
            <select
              className='login-input'
              value={securityQuestion}
              onChange={(e) => setSecurityQuestion(e.target.value)}
              required
            >
              <option value="" disabled>Selecciona una pregunta</option>
              {availableQuestions.map((question, index) => (
                <option key={index} value={question}>{question}</option>
              ))}
            </select>
            <p className='mb-5'>Ingresa tu respuesta:</p>
            <input
              className='login-input'
              type="text"
              placeholder="Ingresa tu respuesta"
              value={securityAnswer}
              onChange={(e) => setSecurityAnswer(e.target.value)}
              required
            />
            <p className='mb-5'>Nueva Contraseña:</p>
            <input
              className='login-input'
              type="password"
              placeholder="Nueva contraseña"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <button className='btn mtb-16' type="submit">Restablecer Contraseña</button>
          </form>
        </div>
      </section>
    </>
  );
};

export default RequestPasswordReset;