import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';


const Register = () => {
  const { signUp, signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [address, setAddress] = useState('');
  const [fullName, setFullName] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState(''); // Estado para la pregunta de seguridad
  const [securityAnswer, setSecurityAnswer] = useState(''); // Estado para la respuesta de seguridad
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Función para validar la contraseña
  const validatePassword = (value) => {
    setPassword(value);
    const hasNumber = /\d/.test(value);
    if (value.length < 6 || !hasNumber) {
      setPasswordMessage("La contraseña debe tener mínimo 6 caracteres y al menos un número");
    } else {
      setPasswordMessage('');
    }
  };

  // Función para generar un correo genérico basado en el nombre y apellido
// Función para generar un correo genérico basado en el nombre y apellido
const generateGenericEmail = (fullName) => {
  // Remover caracteres especiales y dejar solo letras y números
  const cleanedName = fullName.replace(/[^a-zA-Z0-9\s]/g, '').trim().toLowerCase();
  
  const nameParts = cleanedName.split(' ');
  const firstName = nameParts[0];
  const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : 'user';
  
  return `${firstName}.${lastName}@mrt.com`;
};

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Si no hay correo ingresado, generar un correo genérico
      const finalEmail = email.trim() === '' ? generateGenericEmail(fullName) : email;

      // Ahora usamos el correo generado o ingresado como `username` también
      const user = await signUp(finalEmail, password, {
        full_name: fullName,
        phone: phone,
        address: address,
        username: finalEmail,
        security_question: securityQuestion, // Almacenar la pregunta de seguridad
        security_answer: securityAnswer, // Almacenar la respuesta de seguridad
      });

      if (user) {
        message.success('¡Registro exitoso! Bienvenido a MRT');

        // Inicia sesión automáticamente después del registro
        const { error: signInError } = await signIn(finalEmail, password);
        setErrorMessage(error.message); // Aquí manejas los mensajes de error

        if (signInError) throw signInError;

        // Redirige al home después de que el toast se haya mostrado completamente
        navigate('/');
      }
    } catch (error) {
      setError(error.message);
      // message.error('Error durante el registro: ' + error.message);
    }
  };

  return (
    <>
      <section>
        <div className="container mb-75">
          <h1 className='txt-align-center mtb-16'>¡Quiero registrarme!</h1>
          <form onSubmit={handleRegister} className='register-form'>
            <p className='mb-5'>Nombre Completo:</p>
            <input
              className='login-input'
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Juan Pérez Solis"
              required
            />
            {/* <p className='mb-5'>Correo Electrónico (opcional):</p>
            <input
              className='login-input'
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Si no tienes correo, se generará uno automáticamente"
            /> */}
            <p className='mb-5'>Teléfono:</p>
            <input
              className='login-input'
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Ejemplo: 7712341122"
              required
            />
            {/* <p className='mb-5'>Dirección:</p>
            <input
              className='login-input'
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Avenida Landana #345, Colonia Centro, 01234, Pachuca, Hidalgo"
            /> */}

            <p className='mb-5'>Pregunta de Seguridad:</p>
            <select
              className='login-input'
              value={securityQuestion}
              onChange={(e) => setSecurityQuestion(e.target.value)}
              required
            >
              <option value="">Selecciona una pregunta</option>
              <option value="nombre_mascota">¿Cómo se llama tu primera mascota?</option>
              <option value="ciudad_nacimiento">¿En qué ciudad naciste?</option>
              <option value="nombre_abuelo">¿Cómo se llama tu abuelo/a?</option>
            </select>

            <p className='mb-5'>Respuesta de Seguridad:</p>
            <input
              className='login-input'
              type="text"
              value={securityAnswer}
              onChange={(e) => setSecurityAnswer(e.target.value)}
              placeholder="Ingresa la respuesta"
              required
            />

            <p className='mb-5'>Contraseña:</p>
            <div className="password-container">
              <input
                className='login-input'
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => validatePassword(e.target.value)}
                placeholder="Contraseña"
                required
              />
              <button
                type="button"
                className="show-password-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Ocultar" : "Mostrar"}
              </button>
            </div>
            {passwordMessage && <p className="error-message">{passwordMessage}</p>}

            <button className='btn btn-register' type="submit">Registrarse</button>
            {error && <p>{error}</p>}
          </form>
        </div>
      </section>
    </>
  );
};

export default Register;