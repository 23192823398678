import React, { useState } from 'react';

const NoteModal = ({ product, onSave, onClose }) => {
    const [note, setNote] = useState(product.notes || '');

    const handleSave = () => {
        onSave(product.id, note);
    };

    return (
        <div className="modal">
            <div className="modal-content f-v gap-16">
                <h2>{product.name}</h2>
                <textarea
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder="Agregar instrucciones del producto..."
                />
                <button className='btn' onClick={handleSave}>Agregar nota</button>
                <button onClick={onClose}>Cancelar</button>
            </div>
        </div>
    );
};

export default NoteModal;