import React, { useEffect, useState } from 'react';
import { useAuth } from '../components/context/authContext';
import { supabase } from '../components/components/supabaseClient';
import moment from 'moment';

const OrderHistory = () => {
  const { user } = useAuth();
  const [orders, setOrders] = useState([]);
  
  const methodTranslation = {
    'store-pickup': 'Recoger en tienda',
    'foreign-shipping': 'Envío foráneo',
    'package-shipping': 'Envío a domicilio',
    'pickup-point': 'Entrega en punto fijo',
    'national-shipping': 'Paquetería'
};
  // Función para eliminar duplicados
  const removeDuplicates = (ordersArray) => {
    const uniqueOrders = ordersArray.filter((order, index, self) =>
      index === self.findIndex((o) => o.folio === order.folio)
    );
    return uniqueOrders;
  };

  // Función para obtener los pedidos del usuario
  const fetchOrders = async () => {
    try {
      const { data, error } = await supabase
        .from('orders') // Nombre de la tabla de pedidos
        .select('*') // Selecciona todos los campos necesarios
        .eq('user_id', user.id) // Filtra los pedidos por el ID del usuario
        .order('created_at', { ascending: false }); // Ordenar por fecha de creación, más recientes primero

      if (error) {
        console.error('Error al obtener los pedidos:', error);
        return;
      }

      const uniqueOrders = removeDuplicates(data); // Eliminar duplicados
      setOrders(uniqueOrders); // Guardar los pedidos únicos en el estado
    } catch (error) {
      console.error('Error general al obtener los pedidos:', error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchOrders(); // Llamar a la función cuando el componente se monta
    }
  }, [user]);

  if (!user) {
    return <h2>Necesitas iniciar sesión para ver tus pedidos</h2>;
  }

  console.log(orders);
  
  return (
    <section className='mb-75'>
      <div className='container mtb-20 card-white'>
        <h2>Historial de Pedidos</h2>
        {orders.length === 0 ? (
          <p>No has realizado ningún pedido aún.</p>
        ) : (
          <ul>
            {orders.map(order => (
              <li key={order.id} className='order-wrapper'>
                <h3>Pedido #{order.folio}</h3>
                <div className="order-items">
                  {order.items.map((item, index) => (
                    <div key={index} className="order-item">
                      <div className='item-wrapper'>
                        <h4 className='mb-5'>{item.name}</h4>
                        <div className="f-h jc-sb">
                          <p className='mb-5'>Cantidad: {item.quantity}</p>
                          <p>Precio: ${item.price}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <p className='mb-5 txt-align-rigth'>Total: ${order.total}</p>


                
                {/* Mostrar información específica según el método de envío */}
                <div className='order-details'>
                <span className='bold mb-16 mr-8'>Método de envío:</span>
                <span className='mb-16'>{methodTranslation[order.method] || order.method}</span>

                  {order.method === 'store-pickup' && order.store && (
                    <p><strong className='mr-8'>Recoger en:</strong> {order.store}</p>
                  )}
                  

                  {(order.method === 'package-shipping' || order.method === 'national-shipping') && order.address && (
                    <p><strong className='mr-8'>Dirección de Envío:</strong> {order.address}</p>
                  )}

                  {order.method === 'pickup-point' && (
                    <>
                      <p><strong className='mr-8'>Punto de Entrega:</strong> {order.address}</p>
                      <p><strong className='mr-8'>Fecha de Entrega:</strong> {order.delivery_date ? moment(order.delivery_date).format('dddd, D [de] MMMM [de] YYYY') : 'Sin fecha'}</p>
                    </>
                  )}

                  {order.method === 'package-shipping' && (
                    <>
                      <p><strong className='mr-8'>Fecha de Entrega:</strong> {moment(order.delivery_date).format('dddd, D [de] MMMM [de] YYYY')}</p>
                      <p><strong className='mr-8'>Horario de Entrega:</strong> {order.hour || 'No disponible'}</p>
                    </>
                  )}

                  {order.method === 'foreign-shipping' && order.address && (
                    <>
                      <p><strong className='mr-8'>Entrega en:</strong> {order.address}</p>
                      <p><strong className='mr-8'>Fecha de Entrega:</strong> {moment(order.delivery_date).format('dddd, D [de] MMMM [de] YYYY')}</p>
                    </>
                  )}

                  {order.method === 'national-shipping' && (
                    <p className="alert alert-info">
                      <strong className='mr-8'>Nota:</strong> El envío se realizará al siguiente día hábil después de la confirmación del pago.
                    </p>
                  )}
                </div>

                <p className='mb-15'>Fecha del Pedido: {new Date(order.created_at).toLocaleDateString('es-ES', { 
                  weekday: 'long',
                  day: '2-digit', 
                  month: '2-digit', 
                  year: 'numeric', 
                  hour: '2-digit', 
                  minute: '2-digit' 
                })}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
    </section>
  );
};

export default OrderHistory;