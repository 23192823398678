import React from 'react';

const SalePolicies = () => {
    return (
    <section className="mt-20 mb-75">
        <div className="container card-white politicas">
            <h1>Políticas de Venta</h1>
            <p>Gracias por interesarte en nuestra mercancía, te invitamos cordialmente a leer detenidamente nuestras políticas de venta antes de realizar un pedido.</p>
           
            <h2>Horarios de Atención</h2>
            <p>Nuestro horario de atención de WhatsApp es de las 9 AM a 5:00PM de lunes a viernes, los sábados de 9am a 2pm y los domingos no laboramos.</p>

            <h2>¿Puedo hacer mi pedido y recoger en la tienda?</h2>
            <p>Si claro, tienes 1 día para recogerlo o realizar tu anticipo o pago total del mismo y así puedas recogerlo en 1 semana, toma en consideración que pasando el tiempo estimado tu pedido pasará a disponible sin hacer devoluciones.</p>

            <h2>Mecánicas de Compra</h2>
            <ol>
                <li>1. Selecciona los productos requeridos.</li>
                <li>2. La mercancía está sujeta a disponibilidad en temporadas altas.</li>
                <li>3. En caso de no especificar modelo o color, se asignará al azar.</li>
                <li>4. No hay cambios ni devoluciones, revisa tu mercancía al recibirla.</li>
            </ol>

            <h2>Pago con pedido confirmado</h2>
            <p>Si gustas realizar pago total o anticipo de tu pedido para recogerlo en 1 semana tendrás que enviar el comprobante a nuestro WhatsApp en lo que resta del dia.
            Si lo realizas después del tiempo estimado no aseguramos las existencias de tus productos y/o pedido.</p>

            <h2>Proceso de Envíos</h2>
            <ol>
                <li><strong>Domicilio:</strong></li>
                <p><strong>Costo Extra $50.00</strong> solo aplica para Pachuca y mineral de la reforma, si tu distancia es mayor dentro de esta zona se te cobrará un poco más de lo establecido.</p>
                <br></br>
                <li><strong>Entrega en punto (vips plaza bella):</strong></li>
                <p>Al momento de confirmar esta entrega quedamos por enterados. El día establecido nuestro repartidor te estará esperando en el lugar y horario acordado.</p>
                <p>Favor de avisar con anticipación de por lo menos 1 hora cuando no pueda recoger su pedido solo tome en cuenta que pasará a disponible.</p>
                <br></br>
                <li><strong>Paquetería:</strong></li>
                <p>Los envíos se realizan por paquetería FedEx son terrestres, el costo del envío depende del volumen o peso del paquete y el código postal, si al terminar de empacar tu pedido resulta con más volumen o peso se te cobrará el extra, este mismo debe de quedar cubierto para que pueda salir tu pedido, también aplica si tu zona es extendida) en caso de que el paquete sea muy grande se pondrán las guías necesarias (2, 3 o más). Una vez que termines de realizar tu pedido favor de ponerte en contacto con nosotros mediante WhatsApp para el seguimiento.</p>
                <br></br>
                <li><strong>Costo Aproximado: $350.00</strong> no aplica zona extendida (te verificamos el costo)</li>
                <li><strong>Tamaño: paquete: 50X50X50 cm</strong></li>
                <li><strong>Peso: 25 kg</strong></li> 
            </ol>

            <h2>Paquetes</h2>
            <p>Los paquetes se van empacados de la mejor manera, “no somos responsables del trato que le dé paquetería”, si necesitas un embalaje especial pide cotización con atención al cliente.</p>

            <h2>Seguro de paquetería</h2>
            <p>No nos hacemos responsables por robos totales, parciales o artículos rotos por parte de la paquetería.</p>

            <h2>Tiempo de entrega</h2>
            <p>El tiempo de entrega varía según la zona o saturación de paquetería, no hay envíos urgentes, te invitamos a prevenir tus compras.</p>
            <p>La obligación y responsabilidad de darle seguimiento a su guía es únicamente de cliente, por favor darle seguimiento a la misma, si notas alguna anomalía llama a paquetería y posteriormente haznos saber.</p>

            <h2>Políticas de Garantía</h2>
            <p>Para poder hacer válida la garantía en caso de existir algún faltante, es necesario que nos envíes tu video grabado de la siguiente forma:</p>
            <br></br>
            <p>​Empezar el vídeo mostrando las 6 caras de tu caja, sin dejar de enfocar, no deben existir ni cortes ni pausas.</p>
            <br></br>
            <ol>
                <li>No tenemos devoluciones en efectivo, se te dejará un saldo a favor, que podrás utilizar en tu próxima compra.</li>
                <li>Los artículos tendrán que ser sacados de la caja uno por uno y siempre mostrado a cámara, una vez que hayas terminado de sacar todos los productos es necesario que muestres la caja vacía, para corroborar que no quede ningún artículo.</li>
            </ol>
            <br></br>
            <p><strong>Asegúrate de tener pila en tu celular antes de grabar tu video, no hay excusas, sin video no hay garantía, no insista.</strong></p>

            <h2>Atención al Cliente</h2>
            <p>Nuestro número de atención al cliente es <strong>WhatsApp 771 129 16 21</strong>, es nuestro único número, <strong>evita las estafas.</strong></p>

            <h2>Imágenes</h2>
            <p>Nos esforzamos por proporcionar imágenes claras y precisas de nuestros productos para ayudar a nuestros clientes a tomar decisiones de compra informadas. Sin embargo, deseamos destacar que estas imágenes son únicamente de referencia. Las variaciones en color, tamaño, forma y diseño pueden ocurrir debido a la naturaleza del proceso de fabricación o a las diferencias en las pantallas de visualización.</p>
            <p>Por favor, tenga en cuenta que no aceptamos cambios ni devoluciones basadas en diferencias entre la imagen de referencia y el producto real recibido. Al realizar su compra, usted reconoce y acepta esta condición.</p>
            <br></br>

            <p><strong>Nota: No aceptamos cambios ni devoluciones.</strong></p>
        </div>
    </section>
    );
};

export default SalePolicies;