import React, { useState } from 'react';
import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";

const Carousel = () => {
    const videos = [
        "https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/460481916_848314670762516_190199531815935309_n.mp4",
        "https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/433117600_2138866486482367_3391970817700791353_n.mp4",
        "https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/459353144_1994508314302812_2510403320053169811_n.mp4"
    ];

    const [activeIndex, setActiveIndex] = useState(1);  // Empezamos con el índice 1 como default

    const handleWillChange = (e) => {
        setActiveIndex(e.index);
    };

    return (
        <>
            <h1 className='slider-title'>Redes Sociales</h1>
            <Flicking
                className="flicking"
                circular={true}
                defaultIndex={1}
                onWillChange={handleWillChange}
            >
                {videos.map((videoSrc, index) => (
                    <div key={index} className={`panel ${index === activeIndex ? "active" : ""}`}>
                        <video src={videoSrc} controls />
                    </div>
                ))}
            </Flicking>
        </>
    );
};

export default Carousel;