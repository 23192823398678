import React, { useState } from 'react';
import { useAuth } from '../../context/authContext';
import { useNavigate, useLocation } from 'react-router-dom';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const { resetPassword, user } = useAuth(); // Asegúrate de tener acceso al usuario autenticado
  const navigate = useNavigate();

  const handleReset = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setPasswordMessage('La contraseña debe tener al menos 6 caracteres.');
      return;
    }

    try {
      const userId = user?.id; // Aquí obtenemos el ID del usuario actual
      if (!userId) {
        throw new Error('Usuario no autenticado.');
      }

      await resetPassword(userId, password); // Pasamos el ID del usuario a la función
      console.log('¡Contraseña restablecida correctamente!');
    } catch (error) {
      console.log('Error al restablecer la contraseña: ' + error.message);
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <h1 className='txt-align-center mtb-16'>Restablecer Contraseña</h1>
          <form onSubmit={handleReset}>
            <p className='mb-5'>Nueva Contraseña:</p>
            <input
              className='login-input'
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Ingresa tu nueva contraseña"
              required
            />
            <button className='btn' type="submit">Restablecer Contraseña</button>
            {passwordMessage && <p>{passwordMessage}</p>}
          </form>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
