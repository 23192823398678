import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';

const ForgotPasswordIdentifier = () => {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [error, setError] = useState('');
  const { fetchUserByEmailOrPhone } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Verificar si el identificador existe en la base de datos
      const user = await fetchUserByEmailOrPhone(emailOrPhone);
      if (user) {
        // Redirigir a la página de pregunta de seguridad pasando el identificador
        navigate(`/forgot-password/security-question?user=${emailOrPhone}`);
      }
    } catch (error) {
      setError('El correo electrónico o número de teléfono no está registrado.');
    }
  };

  return (
    <>
      <section>
        <div className="container">
          <h1 className='txt-align-center mtb-16'>Recuperar Contraseña</h1>
          <form onSubmit={handleSubmit}>
            <p className='mb-5'>Ingresa tu correo electrónico o número de teléfono:</p>
            <input
              className='login-input'
              type="text"
              value={emailOrPhone}
              onChange={(e) => setEmailOrPhone(e.target.value)}
              placeholder="Correo electrónico o teléfono"
              required
            />
            <button className='btn mtb-16' type="submit">Siguiente</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </form>
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordIdentifier;