import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../context/authContext';

const PrivateRoute = ({ roles }) => {
  const { user } = useAuth();

  // Si no hay un usuario logueado, redirigimos a la página de login
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Si el usuario tiene uno de los roles permitidos, renderizamos el componente solicitado
  if (roles.includes(user.role)) {
    return <Outlet />;
  }

  // Si el usuario no tiene permiso, lo redirigimos a una página no autorizada o de inicio
  return <Navigate to="/" />;
};

export default PrivateRoute;