import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { message } from 'antd';

const SecurityQuestion = () => {
  const [securityAnswer, setSecurityAnswer] = useState('');
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [userId, setUserId] = useState(null); // ID del usuario encontrado
  const { fetchUserByEmailOrPhone, verifySecurityAnswer, resetPasswordManually } = useAuth(); // Cambié resetPassword por resetPasswordManually
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const user = queryParams.get('user');
    if (user) {
      fetchSecurityQuestion(user);
    }
  }, [location.search]);

  const fetchSecurityQuestion = async (user) => {
    try {
      const profile = await fetchUserByEmailOrPhone(user);
      setSecurityQuestion(profile.security_question);
      setUserId(profile.id); // Guardar el ID del usuario para luego actualizar la contraseña
    } catch (error) {
      console.log('Error al buscar la pregunta de seguridad: ' + error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      await verifySecurityAnswer(userId, securityAnswer); // Verifica que la respuesta sea correcta
      await resetPasswordManually(userId, newPassword); // Usa la nueva función para actualizar manualmente la contraseña
      
      // Muestra el mensaje de éxito
      message.success('¡Contraseña restablecida correctamente!', 3);
  
      // Redirige al home después de 3 segundos
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.log('Error al restablecer la contraseña: ' + error.message);
      message.error('Error al restablecer la contraseña: ' + error.message, 3);
    }
  };
  

  return (
    <section>
      <div className="container">
        <h1 className='txt-align-center mtb-16'>Restablecer Contraseña</h1>
        <form onSubmit={handleResetPassword}>
          <p className='mb-5'>Pregunta de seguridad:</p>
          <p>{securityQuestion}</p>
          <input
            className='login-input'
            type="text"
            placeholder="Ingresa tu respuesta"
            value={securityAnswer}
            onChange={(e) => setSecurityAnswer(e.target.value)}
            required
          />
          <p className='mb-5'>Nueva Contraseña:</p>
          <input
            className='login-input'
            type="password"
            placeholder="Nueva contraseña"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <button className='btn mtb-16' type="submit">Restablecer Contraseña</button>
        </form>
      </div>
    </section>
  );
};

export default SecurityQuestion;