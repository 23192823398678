import React, { useState, useEffect } from 'react';
import { useCart } from '../../context/cartContext';
import { supabase } from '../supabaseClient'; // Importa supabase para consultar el stock disponible
import { notification } from 'antd';  // Importa el componente de notificación

const ProductCounter = ({ product }) => {
    const { cart, setCart, removeItemFromCart } = useCart();
    const [quantity, setQuantity] = useState(product.quantity);
    const [maxStock, setMaxStock] = useState(null);

    // Obtener el stock disponible al cargar el componente
    useEffect(() => {
        const fetchStock = async () => {
            const { data: productData, error } = await supabase
                .from('products')
                .select('pieces')
                .eq('id', product.id)
                .single();

            if (error) {
                console.error('Error al obtener el stock:', error);
            } else {
                setMaxStock(productData.pieces); // Guardar el stock máximo disponible
            }
        };

        fetchStock();
    }, [product.id]);

    // Función para mostrar la notificación de Ant Design
    const openNotification = (type, message, description) => {
        notification[type]({
            message: message,
            description: description,
            style: {
                width: 300,
                marginLeft: 335 - 300,
            },
        });
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            const updatedCart = cart.map(item => 
                item.id === product.id ? { ...item, quantity: quantity - 1 } : item
            );
            setCart(updatedCart);
            setQuantity(quantity - 1);
        } else {
            removeItemFromCart(product.id);
            openNotification('warning', 'Producto eliminado', 'Se ha eliminado el producto del carrito.');
        }
    };

    const handleIncrease = () => {
        if (quantity < maxStock) {
            const updatedCart = cart.map(item =>
                item.id === product.id ? { ...item, quantity: quantity + 1 } : item
            );
            setCart(updatedCart);
            setQuantity(quantity + 1);
        } else {
            openNotification('error', 'Límite alcanzado', `Solo puedes agregar máximo ${maxStock} unidades de este producto.`);
        }
    };

    // Manejar la entrada manual del usuario
    const handleQuantityChange = (e) => {
        const inputQuantity = e.target.value; // Almacena el valor sin convertir a número todavía
        setQuantity(inputQuantity); // Permite al usuario escribir libremente
    };

    // Validar el valor ingresado cuando el usuario termina de editar (onBlur)
    const handleBlur = () => {
        let finalQuantity = parseInt(quantity, 10);

        if (isNaN(finalQuantity) || finalQuantity < 1) {
            finalQuantity = 1; // Si el valor ingresado es menor que 1, se reinicia a 1
        } else if (finalQuantity > maxStock) {
            finalQuantity = maxStock; // Si excede el stock, se ajusta al máximo permitido
            openNotification('error', 'Límite alcanzado', `Solo puedes agregar máximo ${maxStock} unidades de este producto.`);
        }

        setQuantity(finalQuantity); // Actualiza la cantidad en el input
        const updatedCart = cart.map(item =>
            item.id === product.id ? { ...item, quantity: finalQuantity } : item
        );
        setCart(updatedCart);
    };

    return (
        <div className="product-counter">
            <button onClick={handleDecrease}>-</button>
            <input
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                onBlur={handleBlur}  // Valida cuando el usuario deja el campo
                min="1"
                max={maxStock}
                className='input-counter'
            />
            <button onClick={handleIncrease}>+</button>
        </div>
    );
};

export default ProductCounter;