import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://gruddavohjypynoyrrqj.supabase.co'; // Reemplaza con tu URL de Supabase
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdydWRkYXZvaGp5cHlub3lycnFqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjExMTc0OTcsImV4cCI6MjAzNjY5MzQ5N30.xMQ9csn4hZbvkW2WuX956TF6401I5lyL0ZB_zoA7nbc'; // Reemplaza con tu clave pública
const supabaseServiceRoleKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdydWRkYXZvaGp5cHlub3lycnFqIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTcyMTExNzQ5NywiZXhwIjoyMDM2NjkzNDk3fQ.IE0Zz6f3UmQF2YfaDckrOtxP3UfLk2dGh25OCU638Tk';

// Crear cliente para usuarios normales
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

// Crear cliente para el servicio/admin
export const supabaseAdmin = createClient(supabaseUrl, supabaseServiceRoleKey);