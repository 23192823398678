import React from 'react';
import { useCart } from '../../context/cartContext';

const TimerDisplay = () => {
  const { timer, isTimerActive } = useCart();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className='timer' style={{ display: isTimerActive ? 'block' : 'none' }}>
      <p>Tiempo para completar la compra: <span className='bold'>{formatTime(timer)}</span></p>
    </div>
  );
};

export default TimerDisplay;