import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import { devUseWarning } from 'antd/es/_util/warning';
import { message } from 'antd';


const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState(''); // Maneja tanto correo como teléfono
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); 
  const [error, setError] = useState('');
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      // Llamada a signIn con email o teléfono
      await signIn(emailOrPhone, password);
      message.success('¡Inicio de sesión exitoso! Bienvenido de nuevo.');
      
      // Redirigir al home después de la autenticación
      navigate('/');
    } catch (error) {
      console.error('Error al iniciar sesión:', error.message);
      setError(error.message);
      message.error('Error al iniciar sesión: ' + error.message);
    }
  };

  const handleRegister = () => {
    navigate('/register');  // Redirigir a la página de registro
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password/identifier');
  };

  const handleGoBack = () => {
    navigate(-1); // Navega a la página anterior
  };

  return (
    <div className='no-overflow'>
        <div className='logo-wrapper-login f-v jc-c a-c'>
          <button onClick={handleGoBack} className="go-back-button">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M6 12L11 7M6 12L11 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
          </button>
          <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/product-images/mrt-logo-blacno.svg" alt="logoMRT" />
          <h1 className='txt-align-center mtb-16'>Iniciar sesión</h1>
        </div>
        <div className='login-section'>
          <div className="container">
            <form className='login-form' onSubmit={handleLogin}>
              <p>Número de teléfono:</p>
              <input
                className='login-input'
                placeholder="Ejemplo: 7712345678"
                value={emailOrPhone}
                onChange={(e) => setEmailOrPhone(e.target.value)} // Asigna el valor de correo o teléfono
                required
              />
              <p>Contraseña:</p>
              <div className="password-container">
                <input
                  className='login-input'
                  type={showPassword ? "text" : "password"} 
                  placeholder="Contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)} 
                  required
                />
                <button
                  type="button"
                  className="show-password-btn"
                  onClick={() => setShowPassword(!showPassword)}  // Cambiar el estado para mostrar u ocultar la contraseña
                >
                  {showPassword ? "Ocultar" : "Mostrar"}  
                </button>
              </div>
              <button className='btn mtb-16' type="submit">Iniciar Sesión</button>
              {error && <p style={{ color: 'red' }}>{error}</p>} {/* Muestra el error si existe */}
            </form>
            <div className='txt-align-center'> ó </div>
            <button className='btn btn-yellow btn-register mtb-16' onClick={handleRegister}>Registrarme</button>
                        {/* Botón de Olvidé mi contraseña */}
                        <div className='txt-align-center mt-10'>
            <button className='btn-link' onClick={handleForgotPassword}>Olvidé mi contraseña</button>
           </div>
          </div>
        </div>
    </div>
  );
};

export default Login;