import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CartContext = createContext();

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem('timer');
    return savedTimer ? Number(savedTimer) : 300; // Si hay un temporizador guardado, úsalo; de lo contrario, 300 segundos
  });

  const [isTimerActive, setIsTimerActive] = useState(() => {
    const savedTimerActive = localStorage.getItem('isTimerActive');
    return savedTimerActive ? JSON.parse(savedTimerActive) : false;
  });

  const [startTime, setStartTime] = useState(() => {
    const savedStartTime = localStorage.getItem('startTime');
    return savedStartTime ? Number(savedStartTime) : null;
  });

  const navigate = useNavigate();

  // Iniciar el temporizador solo cuando el carrito tenga productos
  useEffect(() => {
    if (cart.length > 0 && !isTimerActive) {
      startTimer();
    }
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  // Temporizador que corre en segundo plano
  useEffect(() => {
    if (isTimerActive && startTime) {
      const interval = setInterval(() => {
        const currentTime = Date.now();
        const elapsedSeconds = Math.floor((currentTime - startTime) / 1000); // Segundos transcurridos

        const remainingTime = 300 - elapsedSeconds;
        if (remainingTime <= 0) {
          clearCart();
          setIsTimerActive(false);
          clearInterval(interval);
          navigate('/'); // Redirigir al home cuando el tiempo expire
        } else {
          setTimer(remainingTime); // Actualiza el tiempo restante
          localStorage.setItem('timer', remainingTime); // Guardar el tiempo restante en localStorage
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isTimerActive, startTime, navigate]);

  const startTimer = () => {
    const currentTime = Date.now();
    setStartTime(currentTime); // Establecer la hora de inicio
    localStorage.setItem('startTime', currentTime); // Guardar la marca de tiempo en localStorage
    setIsTimerActive(true);
    localStorage.setItem('isTimerActive', true);
  };

  const addItemToCart = (product, quantity = 1) => {
    setCart(prevCart => {
      const existingProductIndex = prevCart.findIndex(item => item.id === product.id);
      if (existingProductIndex > -1) {
        // Si el producto ya existe en el carrito, actualiza la cantidad
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quantity += quantity;
        return updatedCart;
      } else {
        // Si es el primer producto en el carrito, inicia el temporizador
        if (prevCart.length === 0) {
          startTimer();
        }
        return [...prevCart, { ...product, quantity, notes: '' }];
      }
    });
  };

  const removeItemFromCart = (productId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== productId));
  };

  const clearCart = () => {
    setCart([]);
    setIsTimerActive(false); // Detener el temporizador
    setTimer(300); // Reiniciar el temporizador a 5 minutos
    localStorage.removeItem('cart');
    localStorage.removeItem('startTime'); // Eliminar la marca de tiempo de localStorage
    localStorage.removeItem('timer');
    localStorage.removeItem('isTimerActive');
  };

  const getTotalQuantity = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  return (
    <CartContext.Provider value={{
      cart,
      setCart,
      addItemToCart,
      removeItemFromCart,
      clearCart,
      getTotalQuantity,
      timer, // Exponer el temporizador para que los componentes lo puedan leer
      isTimerActive, // Estado del temporizador
    }}>
      {children}
    </CartContext.Provider>
  );
};