import React, { useState, useEffect } from 'react';
import { supabase } from '../components/components/supabaseClient'; // Asegúrate de importar correctamente supabase

const AdminMaintenanceSwitch = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const { data, error } = await supabase
          .from('site_status')
          .select('is_maintenance')
          .single();

        if (error) {
          throw error;
        }

        setIsMaintenance(data.is_maintenance);
      } catch (error) {
        console.error(error);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  const toggleMaintenanceMode = async () => {
    try {
      const { error } = await supabase
        .from('site_status')
        .update({ is_maintenance: !isMaintenance })
        .eq('id', 1); // Asegúrate de que este ID sea el correcto para tu tabla

      if (error) {
        throw error;
      }

      setIsMaintenance(!isMaintenance);
    } catch (error) {
      console.error('Error al cambiar el estado de mantenimiento:', error);
    }
  };

  return (
    <div className='admin-item admin-item-3'>
        <label className="switch">
            <input type="checkbox" checked={isMaintenance} onChange={toggleMaintenanceMode} />
            <span className="slider-1"></span>
        </label>
        <div>

        <h2 className='mb-5'>Modo de Mantenimiento</h2>
        <p className='font-xs'>El sitio está actualmente <span className='font-xs bold'>{isMaintenance ? 'en mantenimiento' : 'activo'}.</span></p>
        </div>


    </div>
  );
};

export default AdminMaintenanceSwitch;