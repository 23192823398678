// import React, { useEffect, useState } from 'react';
// import { supabase } from '../components/supabaseClient';
// import { jsPDF } from 'jspdf';
// import { MoonLoader } from 'react-spinners';
// import { Button, message, Popconfirm, Input, Table, Checkbox, Modal } from 'antd';
// import moment from 'moment';


// const AdminPage = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [orderBy, setOrderBy] = useState('desc'); // Estado para controlar el orden (desc = más reciente primero)
//   const [searchTerm, setSearchTerm] = useState('');
//   const [viewMode, setViewMode] = useState('list'); // Nuevo estado para controlar la vista
//   const [printedOrders, setPrintedOrders] = useState({}); // Estado para controlar las órdenes impresas

//   const removeDuplicates = (ordersArray) => {
//     const uniqueOrders = ordersArray.filter((order, index, self) =>
//       index === self.findIndex((o) => o.folio === order.folio)
//     );
//     return uniqueOrders;
//   };

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const { data, error } = await supabase
//           .from('orders')
//           .select('*, profiles(full_name, phone)')
//           .order('created_at', { ascending: orderBy === 'asc' });
  
//         if (error) {
//           throw error;
//         }
  
//         const uniqueOrders = removeDuplicates(data);
  
//         setOrders(uniqueOrders);
//       } catch (error) {
//         console.error('Error al obtener los pedidos:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchOrders();
//   }, [orderBy]);
  
//   const filteredOrders = orders.filter(order => {
//     const name = order.profiles?.full_name?.toLowerCase() || '';
//     const phone = String(order.profiles?.phone || '');
//     const term = searchTerm.toLowerCase();
//     return name.includes(term) || phone.includes(term);
//   });

//   const handleOrderChange = (e) => {
//     setOrderBy(e.target.value);
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleDeleteOrder = async (orderId) => {
//     try {
//       const { error } = await supabase
//         .from('orders')
//         .delete()
//         .eq('id', orderId);
      
//       if (error) throw error;

//       message.success('Pedido eliminado con éxito');
//       setOrders(orders.filter(order => order.id !== orderId));
//     } catch (err) {
//       console.error('Error al eliminar el pedido:', err.message);
//       message.error('Error al eliminar el pedido');
//     }
//   };

//   const confirmDelete = (orderId) => {
//     handleDeleteOrder(orderId);
//   };

//   const cancelDelete = () => {
//     message.error('Eliminación cancelada');
//   };

//   const translateMethod = (method) => {
//     switch (method) {
//       case 'store-pickup':
//         return 'Recoger en tienda';
//       case 'package-shipping':
//         return 'Entrega a domicilio';
//       case 'foreign-shipping':
//         return 'Envío foráneo';
//       case 'pickup-point':
//         return 'Entrega en punto fijo';
//       case 'national-shipping':
//         return 'Paquetería';
//       default:
//         return method;
//     }
//   };

//     // Función para actualizar el estado del pedido
//     const handleStatusChange = async (orderId, completed) => {
//       try {
//         const { error } = await supabase
//           .from('orders')
//           .update({ status: completed ? 'Completado' : 'Pendiente' })
//           .eq('id', orderId);
  
//         if (error) throw error;
  
//         message.success('Estado del pedido actualizado');
//         setOrders(orders.map(order => order.id === orderId ? { ...order, status: completed ? 'Completado' : 'Pendiente' } : order));
//       } catch (error) {
//         console.error('Error al actualizar el estado del pedido:', error.message);
//         message.error('Error al actualizar el estado del pedido');
//       }
//     };


//   const renderItems = (items) => {
//     let parsedItems;
    
//     if (typeof items === 'string') {
//       try {
//         parsedItems = JSON.parse(items);
//       } catch (error) {
//         console.error("Error al parsear los items:", error);
//         return <p>Error al cargar items</p>;
//       }
//     } else {
//       parsedItems = items;
//     }
// console.log(items)
  
//     return (
//       <div className="items-container">
//         {parsedItems.map((item, index) => (
//           <div key={index} className="item-card">
//             <h3>Artículo: {item.name}</h3>
//             <p>Cantidad: {item.quantity} {item.quantity > 1 ? 'pzas' : 'pza'}</p>
//             <p>Precio: ${item.price}</p>
//             <p>Nota: {item.notes}</p>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const renderItemsforPrint = (items) => {
//     let parsedItems;
  
//     if (typeof items === 'string') {
//       try {
//         parsedItems = JSON.parse(items);
//       } catch (error) {
//         console.error("Error al parsear los items:", error);
//         return "<p>Error al cargar items</p>";
//       }
//     } else {
//       parsedItems = items;
//     }
  
//     // Generar las filas de productos en párrafos
//     const itemRows = parsedItems.map((item) => `
//         <p><strong>Producto:</strong> ${item.name}</p>
//         <p><strong>Notas:</strong> ${item.notes ? item.notes : 'N/D'}</p>
//         <p><strong>Cantidad:</strong> ${item.quantity}</p>
//         <div style=" display:flex; justify-content:end; ">
//         <p><strong>P/U:</strong> $${item.price}</p>
//         <p><strong>Total:</strong> $${item.quantity * item.price}</p>
//         </div>
//         <hr style="border-bottom:0.5px dashed black;">
//     `).join('');
  
//     return itemRows;
//   };

//   const printOrder = (order) => {
//     // Verificar si la orden ya fue impresa
//     if (printedOrders[order.id]) {
//       Modal.confirm({
//         title: '¿Deseas volver a imprimir el ticket?',
//         onOk: () => {
//           executePrint(order); // Llamar la función para imprimir
//         },
//         onCancel() {
//           message.info('Impresión cancelada');
//         },
//         okText: 'Sí',
//         cancelText: 'No',
//       });
//     } else {
//       executePrint(order); // Llamar la función para imprimir si es la primera vez
//     }
//   };

//   const executePrint = (order) => {
//     const printWindow = window.open('', '_blank');
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>Orden No. ${order.folio}</title>
//           <style>
//             @media print {
//               body {
//                 width: 48mm; /* Ajuste al tamaño del ticket */
//                 margin: 0; 
//                 padding: 0;
//                 font-family: Arial, sans-serif;
//                 font-size: 14px;
//               }
//             }
//             body { 
//               padding: 10px; 
//               width: 48mm;
//             }
//             h1 { font-size: 16px; text-align: center; margin: 5px;}
//             h2 {  font-size: 14px; margin: 5px;}
//             h3 { font-size: 15px; text-align: center; margin: 5px;}
//             p { font-size: 14px; margin: 5px;}
//             .logo { text-align: center; }
//             img { width: 60px; height: auto; }
//             .details { display: flex; justify-content: space-between; }
//             .total { display: flex; justify-content: space-between; margin-top: 10px; }
//             .bold { font-weight: bold; }
//           </style>
//         </head>
//         <body>
//           <div class="logo">
//             <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/mrt-negro.png" alt="Logo MRT" />
//           </div>
//           <p style="text-align:center;"><strong>MRT COMERCIALIZADORA</strong></p>
//           <div class="details">
//             <h2>Folio: ${order.folio}</h2>
//             <h2>${new Date(order.created_at).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h2>          </div>
//           <h3 style="border-bottom:1px solid black;">Datos del Cliente</h3>
//           <div>
//             <h1 class="bold font-size-m">${order.profiles ? order.profiles.full_name : "Nombre no disponible"}</h1>
//             <h1 class="font-size-m"><strong>${order.profiles ? order.profiles.phone : "Teléfono no disponible"}</strong></h1>
//           </div>
//           <div class="details">
//             <p class="bold">Método de Envío:</p>
//             <p>${translateMethod(order.method)}</p>
//           </div>
//           ${
//             order.address
//               ? `
//           <div class="details">
//             <p class="bold">Dirección de Entrega:</p>
//             <p style="text-align:right;">${order.address}</p>
//           </div>
//           `
//               : ""
//           }
//           ${
//             order.hour
//               ? `
//           <div class="details">
//             <p class="bold">Horario de entrega:</p>
//             <p style="text-align:right;">${order.hour}</p>
//           </div>
//           `
//               : ""
//           }
//                     ${
//             order.delivery_date
//               ? `
//           <div class="details">
//             <p class="bold">Día de entrega:</p>
//             <p style="text-align:right;">${order.delivery_date}</p>
//           </div>
//           `
//               : ""
//           }

//           <h3 style="padding:5px 0px; border-bottom: 1px solid black;">Items</h3>
//           ${renderItemsforPrint(order.items)}

//           <div class="total" style="padding-top:10px;">
//             <p><strong>Subtotal:</strong></p>
//             <p>$${order.subtotal}</p>
//           </div>
//           <div class="total">
//             <p><strong>Costo de Envío:</strong></p>
//             <p>${order.shipping_cost == 0 ? 'Gratis' : '$' + order.shipping_cost}</p>
//           </div>
//           <div class="total">
//             <p><strong>Total:</strong></p>
//             <p>$${order.total}</p>
//           </div>
//         </body>
//       </html>
//     `);
//     printWindow.document.close();
//     printWindow.onload = function() {
//       printWindow.print();
//     };

//     // Marcar la orden como impresa
//     setPrintedOrders((prev) => ({ ...prev, [order.id]: true }));
//   };

//   // Definición de las columnas de la tabla para la vista en formato tabla
//   const columns = [
//     { title: 'Folio', dataIndex: 'folio', key: 'folio' },
//     { title: 'Cliente', dataIndex: ['profiles', 'full_name'], key: 'cliente', render: (text) => text || 'Nombre no disponible' },
//     { title: 'Teléfono', dataIndex: ['profiles', 'phone'], key: 'telefono', render: (text) => text || 'Teléfono no disponible' },
//     { title: 'Total', dataIndex: 'total', key: 'total', render: (total) => `$${total}` },
//     { title: 'Fecha', dataIndex: 'created_at', key: 'fecha', render: (date) => new Date(date).toLocaleString() },
//     { title: 'Método de Envío', dataIndex: 'method', key: 'method', render: (method) => translateMethod(method) },
//     {
//       title: 'Completado',
//       key: 'completed',
//       render: (_, order) => (
//         <Checkbox 
//           checked={order.status === 'Completado'}
//           onChange={(e) => handleStatusChange(order.id, e.target.checked)}
//         >
//           Completado
//         </Checkbox>
//       ),
//     },
//     { title: 'Acciones', key: 'acciones', render: (_, order) => (
//       <div>
//         <Button className='mb-10' onClick={() => printOrder(order)}>Imprimir</Button>
//         <Popconfirm
//           title="Eliminar pedido"
//           description="¿Estás seguro de eliminar este pedido?"
//           onConfirm={() => confirmDelete(order.id)} 
//           onCancel={cancelDelete}
//           okText="Sí"
//           cancelText="No"
//         >
//           <Button danger>Eliminar Pedido</Button>
//         </Popconfirm>
//       </div>
//     )}
//   ];

//   // Lógica para alternar entre vistas
//   const toggleViewMode = () => {
//     setViewMode(viewMode === 'list' ? 'table' : 'list');
//   };

//   const rowClassName = (record) => {
//     return record.status === 'Completado' ? 'completed-row' : '';
//   };

//   if (loading) {
//     return (
//       <div className="loading-wrapper">
//         <MoonLoader 
//           className='loader-wrapper'
//           color='#AA0000'
//         /> 
//       </div>
//     );
//   }

//   return (
//     <section>
//       <div className="container mb-75">
//         <div>
//           <h1 className='txt-align-center mtb-20'>Lista de Pedidos</h1>
//           <label>
//             <p className='search-order-label'>
//               Buscar por nombre o teléfono del cliente:
//             </p>
//             <Input 
//               className='search-order'
//               placeholder="Buscar..."
//               value={searchTerm} 
//               onChange={handleSearchChange} 
//               style={{ marginBottom: '20px' }}
//             />
//           </label>
//           <div className='f-h wrap center-mobile mb-16'>
//             <div className='view-toggle txt-align-center'>
//               <Button onClick={toggleViewMode}>
//                 Cambiar a {viewMode === 'list' ? 'Vista de Tabla' : 'Vista de Lista'}
//               </Button>
//             </div>
//             <div className='filter-orders txt-align-center'>
//               <label>Ordenar por:</label>
//               <select value={orderBy} onChange={handleOrderChange}>
//                 <option value="desc">Más reciente primero</option>
//                 <option value="asc">Más antiguo primero</option>
//               </select>
//             </div>
//           </div>

//           {viewMode === 'list' ? (
//             // Vista de lista original
//             filteredOrders.length > 0 ? (
//               filteredOrders.map((order) => (

//                 <div className={`card-white mb-15 ${order.status === 'Completado' ? 'completed-card' : ''}`} key={order.id}>
//                 <div className='f-h jc-sb'>
//                     <div>
//                     <div className='mb-5'>
//                       <span className='bold mr-8 mr-8'>Folio:</span>
//                       <span>{order.folio}</span>
//                     </div>

//                     <div className='mb-5'>
//                       <span className='bold mr-8'>Cliente: </span>
//                       <span>{order.profiles ? order.profiles.full_name : "Nombre no disponible"}</span>
//                     </div>

//                     <div className='mb-5'>
//                       <span className='bold mr-8'>Teléfono:</span>
//                       <span>{order.profiles ? order.profiles.phone : "Teléfono no disponible"}</span>
//                     </div>

//                     {order.method && (
//                       <p className='mb-5 mr-8'><strong>Método de envío:</strong> {translateMethod(order.method)}</p>
//                     )}

//                     <p className='mb-5'>
//                       <strong className='mr-8'>Fecha de entrega:</strong> {moment(order.delivery_date).format('dddd, D [de] MMMM [de] YYYY')}
//                     </p>

//                     {order.address && order.address !== 'EMPTY' && (
//                       <p className='mb-5'><strong className='mr-8'>Dirección de entrega:</strong> {order.address}</p>
//                     )}

//                     {order.method === 'package-shipping' && order.hour && order.delivery_date && (
//                       <>
//                         <p className='mb-5'>
//                           <strong className='mr-8'>Horario de entrega:</strong> {order.hour}
//                         </p>

//                       </>
//                     )}

//                     <div className='mb-5'>
//                       <span className='bold mr-8'>Total:</span>
//                       <span>${order.total}</span>
//                     </div>

//                     <div className='mb-5'>
//                       <span className='bold mr-8'>Fecha del pedido: </span>
//                       <span>{new Date(order.created_at).toLocaleString()}</span>
//                     </div>

//                     <div className='mb-5'>
//                       <span className='bold mr-8'>Estado del pedido:</span>
//                       <span>{order.profiles ? order.status : "Estado no disponible"}</span>
//                     </div> 
//                   </div>
//                   <div>
//                   {order.status === 'Completado' && (
//                     <div className="completed-icon">
//                       <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="90" height="90" viewBox="0,0,256,256">
//                         <g fill="#2db359" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
//                         <g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g>
//                       </svg>
//                     </div>
//                   )}
//                   </div>                    
//                   </div>
//                   <div>{renderItems(order.items)}</div>  {/* Aquí mostramos los items como tarjetas */}
    
//                   <div className='f-h gap-5 mt-20'>
//                     <button className='btn' onClick={() => printOrder(order)}>Imprimir</button>
//                     <button 
//                       className='btn btn-whatsapp' 
//                       onClick={() => window.open(`https://wa.me/${order.profiles?.phone}?text=Hola ${order.profiles?.full_name}, tu pedido con folio ${order.folio} está en proceso.`)}
//                     >
//                       Enviar Whatsapp
//                     </button>
//                     <Popconfirm
//                       title="Eliminar pedido"
//                       description="¿Estás seguro de eliminar este pedido?"
//                       onConfirm={() => confirmDelete(order.id)} // Llamamos a la función para eliminar
//                       onCancel={cancelDelete}
//                       okText="Sí"
//                       cancelText="No"
//                     >
//                       <Button danger>Eliminar Pedido</Button>
//                     </Popconfirm>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p>No hay pedidos disponibles.</p>
//             )
//           ) : (
//             // Nueva vista de tabla
//             <Table 
//             dataSource={filteredOrders} 
//             columns={columns} 
//             rowKey="id" 
//             rowClassName={rowClassName}
//             scroll={{ x: 1000 }} // Ajusta el valor del ancho según el contenido

//             />
//           )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AdminPage;
// import React, { useEffect, useState } from 'react';
// import { supabase } from '../components/supabaseClient';
// import { jsPDF } from 'jspdf';
// import { MoonLoader } from 'react-spinners';
// import { Button, message, Popconfirm, Input, Table, Checkbox, Modal } from 'antd';
// import moment from 'moment';

// const AdminPage = () => {
//   const [orders, setOrders] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [orderBy, setOrderBy] = useState('desc');
//   const [searchTerm, setSearchTerm] = useState('');
//   const [viewMode, setViewMode] = useState('list');
//   const [printedOrders, setPrintedOrders] = useState(() => {
//     const savedPrintedOrders = localStorage.getItem('printedOrders');
//     return savedPrintedOrders ? JSON.parse(savedPrintedOrders) : {};
//   });

//   useEffect(() => {
//     const fetchOrders = async () => {
//       try {
//         const { data, error } = await supabase
//           .from('orders')
//           .select('*, profiles(full_name, phone)')
//           .order('created_at', { ascending: orderBy === 'asc' });
  
//         if (error) {
//           throw error;
//         }
  
//         const uniqueOrders = removeDuplicates(data);
  
//         setOrders(uniqueOrders);
//       } catch (error) {
//         console.error('Error al obtener los pedidos:', error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
  
//     fetchOrders();
//   }, [orderBy]);

//   useEffect(() => {
//     localStorage.setItem('printedOrders', JSON.stringify(printedOrders));
//   }, [printedOrders]);
  
//   const removeDuplicates = (ordersArray) => {
//     const uniqueOrders = ordersArray.filter((order, index, self) =>
//       index === self.findIndex((o) => o.folio === order.folio)
//     );
//     return uniqueOrders;
//   };
  
//   const filteredOrders = orders.filter(order => {
//     const name = order.profiles?.full_name?.toLowerCase() || '';
//     const phone = String(order.profiles?.phone || '');
//     const term = searchTerm.toLowerCase();
//     return name.includes(term) || phone.includes(term);
//   });

//   const handleOrderChange = (e) => {
//     setOrderBy(e.target.value);
//   };

//   const handleSearchChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const handleDeleteOrder = async (orderId) => {
//     try {
//       const { error } = await supabase
//         .from('orders')
//         .delete()
//         .eq('id', orderId);
      
//       if (error) throw error;

//       message.success('Pedido eliminado con éxito');
//       setOrders(orders.filter(order => order.id !== orderId));
//       setPrintedOrders(prev => {
//         const updatedPrintedOrders = { ...prev };
//         delete updatedPrintedOrders[orderId];
//         return updatedPrintedOrders;
//       });
//     } catch (err) {
//       console.error('Error al eliminar el pedido:', err.message);
//       message.error('Error al eliminar el pedido');
//     }
//   };

//   const confirmDelete = (orderId) => {
//     handleDeleteOrder(orderId);
//   };

//   const cancelDelete = () => {
//     message.error('Eliminación cancelada');
//   };

//   const translateMethod = (method) => {
//     switch (method) {
//       case 'store-pickup':
//         return 'Recoger en tienda';
//       case 'package-shipping':
//         return 'Entrega a domicilio';
//       case 'foreign-shipping':
//         return 'Envío foráneo';
//       case 'pickup-point':
//         return 'Entrega en punto fijo';
//       case 'national-shipping':
//         return 'Paquetería';
//       default:
//         return method;
//     }
//   };

//   const handleStatusChange = async (orderId, completed) => {
//     try {
//       const { error } = await supabase
//         .from('orders')
//         .update({ status: completed ? 'Completado' : 'Pendiente' })
//         .eq('id', orderId);
  
//       if (error) throw error;
  
//       message.success('Estado del pedido actualizado');
//       setOrders(orders.map(order => order.id === orderId ? { ...order, status: completed ? 'Completado' : 'Pendiente' } : order));
//     } catch (error) {
//       console.error('Error al actualizar el estado del pedido:', error.message);
//       message.error('Error al actualizar el estado del pedido');
//     }
//   };

//   const renderItems = (items) => {
//     let parsedItems;
    
//     if (typeof items === 'string') {
//       try {
//         parsedItems = JSON.parse(items);
//       } catch (error) {
//         console.error("Error al parsear los items:", error);
//         return <p>Error al cargar items</p>;
//       }
//     } else {
//       parsedItems = items;
//     }
  
//     return (
//       <div className="items-container">
//         {parsedItems.map((item, index) => (
//           <div key={index} className="item-card">
//             <h3>Artículo: {item.name}</h3>
//             <p>Cantidad: {item.quantity} {item.quantity > 1 ? 'pzas' : 'pza'}</p>
//             <p>Precio: ${item.price}</p>
//             <p>Nota: {item.notes}</p>
//           </div>
//         ))}
//       </div>
//     );
//   };

//   const renderItemsforPrint = (items) => {
//     let parsedItems;
  
//     if (typeof items === 'string') {
//       try {
//         parsedItems = JSON.parse(items);
//       } catch (error) {
//         console.error("Error al parsear los items:", error);
//         return "<p>Error al cargar items</p>";
//       }
//     } else {
//       parsedItems = items;
//     }
  
//     const itemRows = parsedItems.map((item) => `
//         <p><strong>Producto:</strong> ${item.name}</p>
//         <p><strong>Notas:</strong> ${item.notes ? item.notes : 'N/D'}</p>
//         <p><strong>Cantidad:</strong> ${item.quantity}</p>
//         <div style=" display:flex; justify-content:end; ">
//         <p><strong>P/U:</strong> $${item.price}</p>
//         <p><strong>Total:</strong> $${item.quantity * item.price}</p>
//         </div>
//         <hr style="border-bottom:0.5px dashed black;">
//     `).join('');
  
//     return itemRows;
//   };



//   // const executePrint = (order) => {
//   //   const printWindow = window.open('', '_blank');
    
//   //   printWindow.document.close();
//   //   printWindow.onload = function() {
//   //     printWindow.print();
//   //   };

//   //   setPrintedOrders((prev) => {
//   //     const updatedPrintedOrders = { ...prev, [order.id]: true };
//   //     return updatedPrintedOrders;
//   //   });
//   // };

//   // const executePrint = async (order) => {
//   //   printWindow.document.write(`
//   //     <html>
//   //       <head>
//   //         <title>Orden No. ${order.folio}</title>
//   //         <style>
//   //           @media print {
//   //             body {
//   //               width: 45mm;
//   //               margin: 0; 
//   //               padding: 0;
//   //               font-family: Arial, sans-serif;
//   //               font-size: 14px;
//   //             }
//   //           }
//   //           body { 
//   //             padding: 10px; 
//   //             width: 45mm;
//   //           }
//   //           h1 { font-size: 16px; text-align: center; margin: 5px;}
//   //           h2 {  font-size: 14px; margin: 5px;}
//   //           h3 { font-size: 15px; text-align: center; margin: 5px;}
//   //           p { font-size: 14px; margin: 5px;}
//   //           .logo { text-align: center; }
//   //           img { width: 60px; height: auto; }
//   //           .details { display: flex; justify-content: space-between; }
//   //           .total { display: flex; justify-content: space-between; margin-top: 10px; }
//   //           .bold { font-weight: bold; }
//   //         </style>
//   //       </head>
//   //       <body>
//   //         <div class="logo">
//   //           <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/mrt-negro.png" alt="Logo MRT" />
//   //         </div>
//   //         <p style="text-align:center;"><strong>MRT COMERCIALIZADORA</strong></p>
//   //         <div class="details">
//   //           <h2>Folio: ${order.folio}</h2>
//   //           <h2>${new Date(order.created_at).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h2>          </div>
//   //         <h3 style="border-bottom:1px solid black;">Datos del Cliente</h3>
//   //         <div>
//   //           <h1 class="bold font-size-m">${order.profiles ? order.profiles.full_name : "Nombre no disponible"}</h1>
//   //           <h1 class="font-size-m"><strong>${order.profiles ? order.profiles.phone : "Teléfono no disponible"}</strong></h1>
//   //         </div>
//   //         <div class="details">
//   //           <p class="bold">Método de Envío:</p>
//   //           <p>${translateMethod(order.method)}</p>
//   //         </div>
//   //         ${
//   //           order.address
//   //             ? `
//   //         <div class="details">
//   //           <p class="bold">Dirección de Entrega:</p>
//   //           <p style="text-align:right;">${order.address}</p>
//   //         </div>
//   //         `
//   //             : ""
//   //         }
//   //         ${
//   //           order.hour
//   //             ? `
//   //         <div class="details">
//   //           <p class="bold">Horario de entrega:</p>
//   //           <p style="text-align:right;">${order.hour}</p>
//   //         </div>
//   //         `
//   //             : ""
//   //         }
//   //                   ${
//   //           order.delivery_date
//   //             ? `
//   //         <div class="details">
//   //           <p class="bold">Día de entrega:</p>
//   //           <p style="text-align:right;">${order.delivery_date}</p>
//   //         </div>
//   //         `
//   //             : ""
//   //         }

//   //         <h3 style="padding:5px 0px; border-bottom: 1px solid black;">Items</h3>
//   //         ${renderItemsforPrint(order.items)}

//   //         <div class="total" style="padding-top:10px;">
//   //           <p><strong>Subtotal:</strong></p>
//   //           <p>$${order.subtotal}</p>
//   //         </div>
//   //         <div class="total">
//   //           <p><strong>Costo de Envío:</strong></p>
//   //           <p>${order.shipping_cost == 0 ? 'Gratis' : '$' + order.shipping_cost}</p>
//   //         </div>
//   //         <div class="total">
//   //           <p><strong>Total:</strong></p>
//   //           <p>$${order.total}</p>
//   //         </div>
//   //       </body>
//   //     </html>
//   //   `);
  
//   //   // Actualizar el estado de `print_status` en la base de datos
//   //   try {
//   //     const { error } = await supabase
//   //       .from('orders')
//   //       .update({ print_status: true })
//   //       .eq('id', order.id);
  
//   //     if (error) throw error;
  
//   //     setPrintedOrders((prev) => {
//   //       const updatedPrintedOrders = { ...prev, [order.id]: true };
//   //       return updatedPrintedOrders;
//   //     });
  
//   //   } catch (error) {
//   //     console.error('Error al actualizar el estado de impresión:', error);
//   //     message.error('Error al actualizar el estado de impresión');
//   //   }
//   // };

//   const printOrder = async (order) => {
//     // Primero verificamos el estado de la impresión desde la base de datos antes de mostrar la alerta
//     try {
//       const { data, error } = await supabase
//         .from('orders')
//         .select('print_status')
//         .eq('id', order.id)
//         .single();
  
//       if (error) throw error;
  
//       if (data.print_status) {
//         // Si el pedido ya fue impreso, mostramos la alerta para preguntar si desea reimprimir
//         Modal.confirm({
//           title: '¿Deseas volver a imprimir el ticket?',
//           onOk: () => {
//             executePrint(order); // Ejecutar la impresión si el usuario confirma
//           },
//           onCancel() {
//             message.info('Impresión cancelada');
//           },
//           okText: 'Sí',
//           cancelText: 'No',
//         });
//       } else {
//         // Si no ha sido impreso, procedemos a la impresión directamente
//         executePrint(order);
//       }
//     } catch (error) {
//       console.error('Error al verificar el estado de impresión:', error);
//       message.error('Error al verificar el estado de impresión');
//     }
//   };
  
//   const executePrint = async (order) => {
//     const printWindow = window.open('', '_blank');
//     printWindow.document.write(`
//       <html>
//         <head>
//           <title>Orden No. ${order.folio}</title>
//           <style>
//             @media print {
//               body {
//                 width: 45mm;
//                 margin: 0; 
//                 padding: 0;
//                 font-family: Arial, sans-serif;
//                 font-size: 14px;
//               }
//             }
//             body { 
//               padding: 10px; 
//               width: 45mm;
//             }
//             h1 { font-size: 16px; text-align: center; margin: 5px;}
//             h2 {  font-size: 14px; margin: 5px;}
//             h3 { font-size: 15px; text-align: center; margin: 5px;}
//             p { font-size: 14px; margin: 5px;}
//             .logo { text-align: center; }
//             img { width: 60px; height: auto; }
//             .details { display: flex; justify-content: space-between; }
//             .total { display: flex; justify-content: space-between; margin-top: 10px; }
//             .bold { font-weight: bold; }
//           </style>
//         </head>
//         <body>
//           <div class="logo">
//             <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/mrt-negro.png" alt="Logo MRT" />
//           </div>
//           <p style="text-align:center;"><strong>MRT COMERCIALIZADORA</strong></p>
//           <div class="details">
//             <h2>Folio: ${order.folio}</h2>
//             <h2>${new Date(order.created_at).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h2>
//           </div>
//           <h3 style="border-bottom:1px solid black;">Datos del Cliente</h3>
//           <div>
//             <h1 class="bold font-size-m">${order.profiles ? order.profiles.full_name : "Nombre no disponible"}</h1>
//             <h1 class="font-size-m"><strong>${order.profiles ? order.profiles.phone : "Teléfono no disponible"}</strong></h1>
//           </div>
//           <div class="details">
//             <p class="bold">Método de Envío:</p>
//             <p>${translateMethod(order.method)}</p>
//           </div>
//           ${order.address ? `<div class="details"><p class="bold">Dirección de Entrega:</p><p style="text-align:right;">${order.address}</p></div>` : ""}
//           ${order.hour ? `<div class="details"><p class="bold">Horario de entrega:</p><p style="text-align:right;">${order.hour}</p></div>` : ""}
//           ${order.delivery_date ? `<div class="details"><p class="bold">Día de entrega:</p><p style="text-align:right;">${order.delivery_date}</p></div>` : ""}
//           <h3 style="padding:5px 0px; border-bottom: 1px solid black;">Items</h3>
//           ${renderItemsforPrint(order.items)}
//           <div class="total" style="padding-top:10px;">
//             <p><strong>Subtotal:</strong></p>
//             <p>$${order.subtotal}</p>
//           </div>
//           <div class="total">
//             <p><strong>Costo de Envío:</strong></p>
//             <p>${order.shipping_cost == 0 ? 'Gratis' : '$' + order.shipping_cost}</p>
//           </div>
//           <div class="total">
//             <p><strong>Total:</strong></p>
//             <p>$${order.total}</p>
//           </div>
//         </body>
//       </html>
//     `);
//     printWindow.document.close();
//     printWindow.onload = function() {
//       printWindow.print();
//     };
  
//     // Actualizar el estado de `print_status` en la base de datos
//     try {
//       // Actualizar print_status en la base de datos
//       const { error } = await supabase
//         .from('orders')
//         .update({ print_status: true })
//         .eq('id', order.id);
  
//       if (error) throw error;
  
//       // Actualiza el estado local `orders` para que refleje el nuevo valor de print_status
//       setOrders((prevOrders) => {
//         return prevOrders.map((o) =>
//           o.id === order.id ? { ...o, print_status: true } : o
//         );
//       });
  
//       setPrintedOrders((prev) => {
//         const updatedPrintedOrders = { ...prev, [order.id]: true };
//         return updatedPrintedOrders;
//       });
  
//       message.success('Estado de impresión actualizado correctamente');
//     } catch (error) {
//       console.error('Error al actualizar el estado de impresión:', error);
//       message.error('Error al actualizar el estado de impresión');
//     }
//   };
 

//   const columns = [
//     { title: 'Folio', dataIndex: 'folio', key: 'folio' },
//     { title: 'Cliente', dataIndex: ['profiles', 'full_name'], key: 'cliente', render: (text) => text || 'Nombre no disponible' },
//     { title: 'Teléfono', dataIndex: ['profiles', 'phone'], key: 'telefono', render: (text) => text || 'Teléfono no disponible' },
//     { title: 'Total', dataIndex: 'total', key: 'total', render: (total) => `$${total}` },
//     { title: 'Fecha', dataIndex: 'created_at', key: 'fecha', render: (date) => new Date(date).toLocaleString() },
//     { title: 'Método de Envío', dataIndex: 'method', key: 'method', render: (method) => translateMethod(method) },
//     {
//       title: 'Completado',
//       key: 'completed',
//       render: (_, order) => (
//         <Checkbox 
//           checked={order.status === 'Completado'}
//           onChange={(e) => handleStatusChange(order.id, e.target.checked)}
//         >
//           Completado
//         </Checkbox>
//       ),
//     },
//     { title: 'Acciones', key: 'acciones', render: (_, order) => (
//       <div>
//         <Button className='mb-10' onClick={() => printOrder(order)}>Imprimir</Button>
//         <Popconfirm
//           title="Eliminar pedido"
//           description="¿Estás seguro de eliminar este pedido?"
//           onConfirm={() => confirmDelete(order.id)} 
//           onCancel={cancelDelete}
//           okText="Sí"
//           cancelText="No"
//         >
//           <Button danger>Eliminar Pedido</Button>
//         </Popconfirm>
//       </div>
//     )}
//   ];

//   const toggleViewMode = () => {
//     setViewMode(viewMode === 'list' ? 'table' : 'list');
//   };

//   const rowClassName = (order) => {
//     let className = '';
//     if (order.status === 'Completado') {
//       className += ' completed-card';
//     }
//     if (order.print_status) {
//       className += 'printed-row';
//     }
//     return className;
//   };

//   if (loading) {
//     return (
//       <div className="loading-wrapper">
//         <MoonLoader 
//           className='loader-wrapper'
//           color='#AA0000'
//         /> 
//       </div>
//     );
//   }

//   return (
//     <section>
//       <div className="container mb-75">
//         <div>
//           <h1 className='txt-align-center mtb-20'>Lista de Pedidos</h1>
//           <label>
//             <p className='search-order-label'>
//               Buscar por nombre o teléfono del cliente:
//             </p>
//             <Input 
//               className='search-order'
//               placeholder="Buscar..."
//               value={searchTerm} 
//               onChange={handleSearchChange} 
//               style={{ marginBottom: '20px' }}
//             />
//           </label>
//           <div className='f-h wrap center-mobile mb-16'>
//             <div className='view-toggle txt-align-center'>
//               <Button onClick={toggleViewMode}>
//                 Cambiar a {viewMode === 'list' ? 'Vista de Tabla' : 'Vista de Lista'}
//               </Button>
//             </div>
//             <div className='filter-orders txt-align-center'>
//               <label>Ordenar por:</label>
//               <select value={orderBy} onChange={handleOrderChange}>
//                 <option value="desc">Más reciente primero</option>
//                 <option value="asc">Más antiguo primero</option>
//               </select>
//             </div>
//           </div>

//           {viewMode === 'list' ? (
//             filteredOrders.length > 0 ? (
//               filteredOrders.map((order) => (
//                 <div className={`card-white mb-15 ${rowClassName(order)}`} key={order.id}>
//                   <div className='f-h jc-sb'>
//                     <div>
//                       <div className='mb-5'>
//                         <span className='bold mr-8 mr-8'>Folio:</span>
//                         <span>{order.folio}</span>
//                       </div>
//                       <div className='mb-5'>
//                         <span className='bold mr-8'>Cliente: </span>
//                         <span>{order.profiles ? order.profiles.full_name : "Nombre no disponible"}</span>
//                       </div>
//                       <div className='mb-5'>
//                         <span className='bold mr-8'>Teléfono:</span>
//                         <span>{order.profiles ? order.profiles.phone : "Teléfono no disponible"}</span>
//                       </div>
//                       {order.method && (
//                         <p className='mb-5 mr-8'><strong>Método de envío:</strong> {translateMethod(order.method)}</p>
//                       )}
//                       <p className='mb-5'>
//                         <strong className='mr-8'>Fecha de entrega:</strong> {moment(order.delivery_date).format('dddd, D [de] MMMM [de] YYYY')}
//                       </p>
//                       {order.address && order.address !== 'EMPTY' && (
//                         <p className='mb-5'><strong className='mr-8'>Dirección de entrega:</strong> {order.address}</p>
//                       )}
//                       {order.method === 'package-shipping' && order.hour && order.delivery_date && (
//                         <>
//                           <p className='mb-5'>
//                             <strong className='mr-8'>Horario de entrega:</strong> {order.hour}
//                           </p>
//                         </>
//                       )}
//                       <div className='mb-5'>
//                         <span className='bold mr-8'>Total:</span>
//                         <span>${order.total}</span>
//                       </div>
//                       <div className='mb-5'>
//                         <span className='bold mr-8'>Fecha del pedido: </span>
//                         <span>{new Date(order.created_at).toLocaleString()}</span>
//                       </div>
//                       <div className='mb-5'>
//                         <span className='bold mr-8'>Estado del pedido:</span>
//                         <span>{order.profiles ? order.status : "Estado no disponible"}</span>
//                       </div> 
//                     </div>
//                     <div>
//                       {order.status === 'Completado' && (
//                         <div className="completed-icon">
//                           <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="90" height="90" viewBox="0,0,256,256">
//                             <g fill="#2db359" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
//                             <g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g>
//                           </svg>
//                         </div>
//                       )}
//                     </div>                    
//                   </div>
//                   {order.print_status && (
//                     <div className="printed-label">Impreso</div>
//                   )}
//                   <div>{renderItems(order.items)}</div>
      
//                   <div className='f-h gap-5 mt-20'>
//                     <button className='btn' onClick={() => printOrder(order)}>Imprimir</button>
//                     <button className=''>Marcar como impreso</button>
//                     <button 
//                       className='btn btn-whatsapp' 
//                       onClick={() => window.open(`https://wa.me/${order.profiles?.phone}?text=Hola ${order.profiles?.full_name}, tu pedido con folio ${order.folio} está en proceso.`)}
//                     >
//                       Enviar Whatsapp
//                     </button>
//                     <Popconfirm
//                       title="Eliminar pedido"
//                       description="¿Estás seguro de eliminar este pedido?"
//                       onConfirm={() => confirmDelete(order.id)}
//                       onCancel={cancelDelete}
//                       okText="Sí"
//                       cancelText="No"
//                     >
//                       <Button danger>Eliminar Pedido</Button>
//                     </Popconfirm>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p>No hay pedidos disponibles.</p>
//             )
//           ) : (
//             <Table 
//               dataSource={filteredOrders} 
//               columns={columns} 
//               rowKey="id" 
//               rowClassName={rowClassName}
//               scroll={{ x: 1000 }}
//             />
//           )}
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AdminPage;

import React, { useEffect, useState } from 'react';
import { supabase } from '../components/supabaseClient';
import { jsPDF } from 'jspdf';
import { MoonLoader } from 'react-spinners';
import { Button, message, Popconfirm, Input, Table, Checkbox, Modal } from 'antd';
import moment from 'moment';

const AdminPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('list');

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const { data, error } = await supabase
          .from('orders')
          .select('*, profiles(full_name, phone)')
          .order('created_at', { ascending: orderBy === 'asc' });
  
        if (error) {
          throw error;
        }
  
        const uniqueOrders = removeDuplicates(data);
  
        setOrders(uniqueOrders);
      } catch (error) {
        console.error('Error al obtener los pedidos:', error.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchOrders();
  }, [orderBy]);

  const removeDuplicates = (ordersArray) => {
    const uniqueOrders = ordersArray.filter((order, index, self) =>
      index === self.findIndex((o) => o.folio === order.folio)
    );
    return uniqueOrders;
  };
  
  const filteredOrders = orders.filter(order => {
    const name = order.profiles?.full_name?.toLowerCase() || '';
    const phone = String(order.profiles?.phone || '');
    const term = searchTerm.toLowerCase();
    return name.includes(term) || phone.includes(term);
  });

  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      const { error } = await supabase
        .from('orders')
        .delete()
        .eq('id', orderId);
      
      if (error) throw error;

      message.success('Pedido eliminado con éxito');
      setOrders(orders.filter(order => order.id !== orderId));
    } catch (err) {
      console.error('Error al eliminar el pedido:', err.message);
      message.error('Error al eliminar el pedido');
    }
  };

  const confirmDelete = (orderId) => {
    handleDeleteOrder(orderId);
  };

  const cancelDelete = () => {
    message.error('Eliminación cancelada');
  };

  const translateMethod = (method) => {
    switch (method) {
      case 'store-pickup':
        return 'Recoger en tienda';
      case 'package-shipping':
        return 'Entrega a domicilio';
      case 'foreign-shipping':
        return 'Envío foráneo';
      case 'pickup-point':
        return 'Entrega en punto fijo';
      case 'national-shipping':
        return 'Paquetería';
      default:
        return method;
    }
  };

  const handleStatusChange = async (orderId, completed) => {
    try {
      const { error } = await supabase
        .from('orders')
        .update({ status: completed ? 'Completado' : 'Pendiente' })
        .eq('id', orderId);
  
      if (error) throw error;
  
      message.success('Estado del pedido actualizado');
      setOrders(orders.map(order => order.id === orderId ? { ...order, status: completed ? 'Completado' : 'Pendiente' } : order));
    } catch (error) {
      console.error('Error al actualizar el estado del pedido:', error.message);
      message.error('Error al actualizar el estado del pedido');
    }
  };

  const updatePrintStatus = async (orderId, status) => {
    try {
      const { error } = await supabase
        .from('orders')
        .update({ print_status: status })
        .eq('id', orderId);
  
      if (error) throw error;
  
      setOrders((prevOrders) => {
        return prevOrders.map((o) =>
          o.id === orderId ? { ...o, print_status: status } : o
        );
      });
  
      message.success('Estado de impresión actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el estado de impresión:', error);
      message.error('Error al actualizar el estado de impresión');
    }
  };

  const renderItems = (items) => {
    let parsedItems;
    
    if (typeof items === 'string') {
      try {
        parsedItems = JSON.parse(items);
      } catch (error) {
        console.error("Error al parsear los items:", error);
        return <p>Error al cargar items</p>;
      }
    } else {
      parsedItems = items;
    }
  
    return (
      <div className="items-container">
        {parsedItems.map((item, index) => (
          <div key={index} className="item-card">
            <h3>Artículo: {item.name}</h3>
            <p>Cantidad: {item.quantity} {item.quantity > 1 ? 'pzas' : 'pza'}</p>
            <p>Precio: ${item.price}</p>
            <p>Nota: {item.notes}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderItemsforPrint = (items) => {
    let parsedItems;
  
    if (typeof items === 'string') {
      try {
        parsedItems = JSON.parse(items);
      } catch (error) {
        console.error("Error al parsear los items:", error);
        return "<p>Error al cargar items</p>";
      }
    } else {
      parsedItems = items;
    }
  
    const itemRows = parsedItems.map((item) => `
        <p><strong>Producto:</strong> ${item.name}</p>
        <p><strong>Notas:</strong> ${item.notes ? item.notes : 'N/D'}</p>
        <p><strong>Cantidad:</strong> ${item.quantity}</p>
        <div style=" display:flex; justify-content:end; ">
        <p><strong>P/U:</strong> $${item.price}</p>
        <p><strong>Total:</strong> $${item.quantity * item.price}</p>
        </div>
        <hr style="border-bottom:0.5px dashed black;">
    `).join('');
  
    return itemRows;
  };

  const printOrder = async (order) => {
    // Primero verificamos el estado de la impresión desde la base de datos antes de mostrar la alerta
    try {
      const { data, error } = await supabase
        .from('orders')
        .select('print_status')
        .eq('id', order.id)
        .single();
  
      if (error) throw error;
  
      if (data.print_status) {
        // Si el pedido ya fue impreso, mostramos la alerta para preguntar si desea reimprimir
        Modal.confirm({
          title: '¿Deseas volver a imprimir el ticket?',
          onOk: () => {
            executePrint(order); // Ejecutar la impresión si el usuario confirma
          },
          onCancel() {
            message.info('Impresión cancelada');
          },
          okText: 'Sí',
          cancelText: 'No',
        });
      } else {
        // Si no ha sido impreso, procedemos a la impresión directamente
        executePrint(order);
      }
    } catch (error) {
      console.error('Error al verificar el estado de impresión:', error);
      message.error('Error al verificar el estado de impresión');
    }
  };
  
  const executePrint = async (order) => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Orden No. ${order.folio}</title>
          <style>
            @media print {
              body {
                width: 45mm;
                margin: 0; 
                padding: 0;
                font-family: Arial, sans-serif;
                font-size: 14px;
              }
            }
            body { 
              padding: 10px; 
              width: 45mm;
            }
            h1 { font-size: 16px; text-align: center; margin: 5px;}
            h2 {  font-size: 14px; margin: 5px;}
            h3 { font-size: 15px; text-align: center; margin: 5px;}
            p { font-size: 14px; margin: 5px;}
            .logo { text-align: center; }
            img { width: 60px; height: auto; }
            .details { display: flex; justify-content: space-between; }
            .total { display: flex; justify-content: space-between; margin-top: 10px; }
            .bold { font-weight: bold; }
          </style>
        </head>
        <body>
          <div class="logo">
            <img src="https://gruddavohjypynoyrrqj.supabase.co/storage/v1/object/public/order-files/mrt-negro.png" alt="Logo MRT" />
          </div>
          <p style="text-align:center;"><strong>MRT COMERCIALIZADORA</strong></p>
          <div class="details">
            <h2>Folio: ${order.folio}</h2>
            <h2>${new Date(order.created_at).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</h2>
          </div>
          <h3 style="border-bottom:1px solid black;">Datos del Cliente</h3>
          <div>
            <h1 class="bold font-size-m">${order.profiles ? order.profiles.full_name : "Nombre no disponible"}</h1>
            <h1 class="font-size-m"><strong>${order.profiles ? order.profiles.phone : "Teléfono no disponible"}</strong></h1>
          </div>
          <div class="details">
            <p class="bold">Método de Envío:</p>
            <p>${translateMethod(order.method)}</p>
          </div>
          ${order.address ? `<div class="details"><p class="bold">Dirección de Entrega:</p><p style="text-align:right;">${order.address}</p></div>` : ""}
          ${order.hour ? `<div class="details"><p class="bold">Horario de entrega:</p><p style="text-align:right;">${order.hour}</p></div>` : ""}
          ${order.delivery_date ? `<div class="details"><p class="bold">Día de entrega:</p><p style="text-align:right;">${order.delivery_date}</p></div>` : ""}
          <h3 style="padding:5px 0px; border-bottom: 1px solid black;">Items</h3>
          ${renderItemsforPrint(order.items)}
          <div class="total" style="padding-top:10px;">
            <p><strong>Subtotal:</strong></p>
            <p>$${order.subtotal}</p>
          </div>
          <div class="total">
            <p><strong>Costo de Envío:</strong></p>
            <p>${order.shipping_cost == 0 ? 'Gratis' : '$' + order.shipping_cost}</p>
          </div>
          <div class="total">
            <p><strong>Total:</strong></p>
            <p>$${order.total}</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = function() {
      printWindow.print();
    };
  
    // Actualizar el estado de `print_status` en la base de datos
    try {
      const { error } = await supabase
        .from('orders')
        .update({ print_status: true })
        .eq('id', order.id);
  
      if (error) throw error;
  
      // Actualiza el estado local `orders` para que refleje el nuevo valor de print_status
      setOrders((prevOrders) => {
        return prevOrders.map((o) =>
          o.id === order.id ? { ...o, print_status: true } : o
        );
      });
  
      message.success('Estado de impresión actualizado correctamente');
    } catch (error) {
      console.error('Error al actualizar el estado de impresión:', error);
      message.error('Error al actualizar el estado de impresión');
    }
  };

  const updatePrintStatusManually = async (orderId) => {
    try {
      const { error } = await supabase
        .from('orders')
        .update({ print_status: true })
        .eq('id', orderId);
  
      if (error) throw error;
  
      setOrders((prevOrders) => {
        return prevOrders.map((o) =>
          o.id === orderId ? { ...o, print_status: true } : o
        );
      });
  
      message.success('Estado de impresión actualizado manualmente');
    } catch (error) {
      console.error('Error al actualizar el estado de impresión manualmente:', error);
      message.error('Error al actualizar el estado de impresión manualmente');
    }
  };

  const columns = [
    { title: 'Folio', dataIndex: 'folio', key: 'folio' },
    { title: 'Cliente', dataIndex: ['profiles', 'full_name'], key: 'cliente', render: (text) => text || 'Nombre no disponible' },
    { title: 'Teléfono', dataIndex: ['profiles', 'phone'], key: 'telefono', render: (text) => text || 'Teléfono no disponible' },
    { title: 'Total', dataIndex: 'total', key: 'total', render: (total) => `$${total}` },
    { title: 'Fecha', dataIndex: 'created_at', key: 'fecha', render: (date) => new Date(date).toLocaleString() },
    { title: 'Método de Envío', dataIndex: 'method', key: 'method', render: (method) => translateMethod(method) },
    {
      title: 'Completado',
      key: 'completed',
      render: (_, order) => (
        <Checkbox 
          checked={order.status === 'Completado'}
          onChange={(e) => handleStatusChange(order.id, e.target.checked)}
        >
          Completado
        </Checkbox>
      ),
    },
    { title: 'Acciones', key: 'acciones', render: (_, order) => (
      <div>
        <Button className='mb-10' onClick={() => printOrder(order)}>Imprimir</Button>
        <Button className='mb-10' onClick={() => updatePrintStatusManually(order.id)}>Marcar como impreso</Button>
        <Popconfirm
          title="Eliminar pedido"
          description="¿Estás seguro de eliminar este pedido?"
          onConfirm={() => confirmDelete(order.id)} 
          onCancel={cancelDelete}
          okText="Sí"
          cancelText="No"
        >
          <Button danger>Eliminar Pedido</Button>
        </Popconfirm>
      </div>
    )}
  ];

  const toggleViewMode = () => {
    setViewMode(viewMode === 'list' ? 'table' : 'list');
  };

  const rowClassName = (order) => {
    let className = '';
    if (order.status === 'Completado') {
      className += ' completed-card';
    }
    if (order.print_status) {
      className += ' printed-row';
    }
    return className;
  };

  if (loading) {
    return (
      <div className="loading-wrapper">
        <MoonLoader 
          className='loader-wrapper'
          color='#AA0000'
        /> 
      </div>
    );
  }

  return (
    <section>
      <div className="container mb-75">
        <div>
          <h1 className='txt-align-center mtb-20'>Lista de Pedidos</h1>
          <label>
            <p className='search-order-label'>
              Buscar por nombre o teléfono del cliente:
            </p>
            <Input 
              className='search-order'
              placeholder="Buscar..."
              value={searchTerm} 
              onChange={handleSearchChange} 
              style={{ marginBottom: '20px' }}
            />
          </label>
          <div className='f-h wrap center-mobile mb-16'>
            <div className='view-toggle txt-align-center'>
              <Button onClick={toggleViewMode}>
                Cambiar a {viewMode === 'list' ? 'Vista de Tabla' : 'Vista de Lista'}
              </Button>
            </div>
            <div className='filter-orders txt-align-center'>
              <label>Ordenar por:</label>
              <select value={orderBy} onChange={handleOrderChange}>
                <option value="desc">Más reciente primero</option>
                <option value="asc">Más antiguo primero</option>
              </select>
            </div>
          </div>

          {viewMode === 'list' ? (
            filteredOrders.length > 0 ? (
              filteredOrders.map((order) => (
                <div className={`card-white mb-15 ${rowClassName(order)}`} key={order.id}>
                  <div className='f-h jc-sb'>
                    <div>
                      <div className='mb-5'>
                        <span className='bold mr-8 mr-8'>Folio:</span>
                        <span>{order.folio}</span>
                      </div>
                      <div className='mb-5'>
                        <span className='bold mr-8'>Cliente: </span>
                        <span>{order.profiles ? order.profiles.full_name : "Nombre no disponible"}</span>
                      </div>
                      <div className='mb-5'>
                        <span className='bold mr-8'>Teléfono:</span>
                        <span>{order.profiles ? order.profiles.phone : "Teléfono no disponible"}</span>
                      </div>
                      {order.method && (
                        <p className='mb-5 mr-8'><strong>Método de envío:</strong> {translateMethod(order.method)}</p>
                      )}
                      <p className='mb-5'>
                        <strong className='mr-8'>Fecha de entrega:</strong> {moment(order.delivery_date).format('dddd, D [de] MMMM [de] YYYY')}
                      </p>
                      {order.address && order.address !== 'EMPTY' && (
                        <p className='mb-5'><strong className='mr-8'>Dirección de entrega:</strong> {order.address}</p>
                      )}
                      {order.method === 'package-shipping' && order.hour && order.delivery_date && (
                        <>
                          <p className='mb-5'>
                            <strong className='mr-8'>Horario de entrega:</strong> {order.hour}
                          </p>
                        </>
                      )}
                      <div className='mb-5'>
                        <span className='bold mr-8'>Total:</span>
                        <span>${order.total}</span>
                      </div>
                      <div className='mb-5'>
                        <span className='bold mr-8'>Fecha del pedido: </span>
                        <span>{new Date(order.created_at).toLocaleString()}</span>
                      </div>
                      <div className='mb-5'>
                        <span className='bold mr-8'>Estado del pedido:</span>
                        <span>{order.profiles ? order.status : "Estado no disponible"}</span>
                      </div> 
                    </div>
                    <div>
                      {order.status === 'Completado' && (
                        <div className="completed-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="90" height="90" viewBox="0,0,256,256">
                            <g fill="#2db359" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none">
                            <g transform="scale(5.12,5.12)"><path d="M25,2c-12.682,0 -23,10.318 -23,23c0,12.683 10.318,23 23,23c12.683,0 23,-10.317 23,-23c0,-12.682 -10.317,-23 -23,-23zM35.827,16.562l-11.511,16.963l-8.997,-8.349c-0.405,-0.375 -0.429,-1.008 -0.053,-1.413c0.375,-0.406 1.009,-0.428 1.413,-0.053l7.29,6.764l10.203,-15.036c0.311,-0.457 0.933,-0.575 1.389,-0.266c0.458,0.31 0.577,0.932 0.266,1.39z"></path></g></g>
                          </svg>
                        </div>
                      )}
                    </div>                    
                  </div>
                  {order.print_status && (
                    <div className="printed-label">Impreso</div>
                  )}
                  <div>{renderItems(order.items)}</div>
      
                  <div className='f-h gap-5 mt-20'>
                    <button className='btn' onClick={() => printOrder(order)}>Imprimir</button>
                    <Button className='mb-10' onClick={() => updatePrintStatus(order.id, !order.print_status)}>
                      {order.print_status ? 'Marcar como No Impreso' : 'Marcar como Impreso'}
                    </Button>

                    <Popconfirm
                      title="Eliminar pedido"
                      description="¿Estás seguro de eliminar este pedido?"
                      onConfirm={() => confirmDelete(order.id)}
                      onCancel={cancelDelete}
                      okText="Sí"
                      cancelText="No"
                    >
                      <Button danger>Eliminar Pedido</Button>
                    </Popconfirm>
                    <button 
                      className='btn btn-whatsapp' 
                      onClick={() => window.open(`https://wa.me/${order.profiles?.phone}?text=Hola ${order.profiles?.full_name}, tu pedido con folio ${order.folio} está en proceso.`)}
                    >
                      Enviar Whatsapp
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <p>No hay pedidos disponibles.</p>
            )
          ) : (
            <Table 
              dataSource={filteredOrders} 
              columns={columns} 
              rowKey="id" 
              rowClassName={rowClassName}
              scroll={{ x: 1000 }}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default AdminPage;