import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase, supabaseAdmin } from '../components/supabaseClient';
import { MoonLoader } from 'react-spinners';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);


export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUserProfile = async (user) => {
    try {
      console.log('Fetching profile for user:', user);
      const { data: profile, error } = await supabase
        .from('profiles')
        .select('user_role, full_name, phone, address')
        .eq('id', user.id)
        .single();

      if (error) {
        console.error('Error al obtener el perfil:', error);
        return null;
      }

      return { 
        ...user, 
        role: profile.user_role, 
        full_name: profile.full_name, 
        phone: profile.phone, 
        address: profile.address, 
        security_question: profile.security_question, 
        security_answer: profile.security_answer 
      };
    } catch (error) {
      console.error('Error general:', error);
      return null;
    }
  };

    // Implementar función para buscar usuario por correo o teléfono
    const fetchUserByEmailOrPhone = async (emailOrPhone) => {
      try {
        const isPhone = /^\d+$/.test(emailOrPhone); // Verifica si es número de teléfono
        let query;
  
        if (isPhone) {
          // Buscar por teléfono
          query = supabase
            .from('profiles')
            .select('*')
            .eq('phone', emailOrPhone)
            .single();
        } else {
          // Buscar por correo electrónico (username)
          query = supabase
            .from('profiles')
            .select('*')
            .eq('username', emailOrPhone)
            .single();
        }
  
        const { data: user, error } = await query;
  
        if (error || !user) {
          throw new Error('Usuario no encontrado');
        }
  
        return user;
      } catch (error) {
        console.error('Error al buscar usuario por correo o teléfono:', error.message);
        throw error;
      }
    };

    const verifySecurityAnswer = async (userId, providedAnswer) => {
      console.log(`Verificando la respuesta de seguridad para el ID de usuario: ${userId}`);
      
      try {
        // Buscar al usuario directamente por su ID
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('security_question, security_answer')
          .eq('id', userId) // Buscar por ID
          .maybeSingle(); // Esto maneja el caso de que no se encuentre nada sin lanzar un error
    
        if (profileError || !profile) {
          throw new Error('Usuario no encontrado.');
        }
    
        // Verificar la respuesta de seguridad
        if (profile.security_answer.trim().toLowerCase() !== providedAnswer.trim().toLowerCase()) {
          throw new Error('La respuesta de seguridad es incorrecta.');
        }
    
        console.log('¡Respuesta de seguridad correcta!');
        return true;
      
      } catch (error) {
        console.error('Error al verificar la respuesta de seguridad:', error.message);
        throw error;
      }
    };

  const checkSession = async () => {
    console.log('Verificando la sesión...');
    const { data: { session }, error } = await supabase.auth.getSession();
    if (error) {
      console.error('Error obteniendo la sesión:', error);
      setUser(null);
    } else if (session) {
      console.log('Sesión encontrada:', session);
      const userWithProfile = await fetchUserProfile(session.user);
      setUser(userWithProfile);
      console.log('Perfil del usuario cargado:', userWithProfile);
    } else {
      console.log('No hay sesión encontrada');
      setUser(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    checkSession();
  }, []);

  // const signUp = async (email, password, additionalData) => {
  //   try {
  //     const { data: { user }, error } = await supabase.auth.signUp({
  //       email,
  //       password,
  //       options: {
  //         data: {
  //           full_name: additionalData.full_name,
  //         }
  //       }
  //     });

  //     if (error) throw error;

  //     // Inserción de datos adicionales en la tabla 'profiles'
  //     const { error: profileError } = await supabase
  //       .from('profiles')
  //       .insert({
  //         id: user.id,
  //         full_name: additionalData.full_name,
  //         phone: additionalData.phone,
  //         address: additionalData.address,
  //         username: additionalData.username, // Asegúrate de insertar el username
  //         security_question: additionalData.security_question,
  //         security_answer: additionalData.security_answer, // Aquí se guarda la respuesta
  //       });

  //     if (profileError) throw profileError;

  //     return user;
      
  //   } catch (err) {
  //     console.error('Error durante el registro:', err.message);
  //     throw err;
  //   }
  // };
  const signUp = async (email, password, additionalData) => {
    try {
      // Validar si el teléfono ya está registrado
      const { data: existingProfile, error: profileError } = await supabase
        .from('profiles')
        .select('phone')
        .eq('phone', additionalData.phone)
        .single();
  
      // Si el teléfono ya está registrado, lanzamos un error y detenemos el registro
      if (existingProfile) {
        throw new Error("El número de teléfono ya fue registrado, ingrese uno diferente.");
      }
  
      // Registro en el sistema de autenticación (solo se llega aquí si no hay error con el teléfono)
      const { data: { user }, error: authError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: additionalData.full_name,
          }
        }
      });
  
      if (authError) throw authError;
  
      // Inserción de datos adicionales en la tabla 'profiles'
      const { error: profileInsertError } = await supabase
        .from('profiles')
        .insert({
          id: user.id,
          full_name: additionalData.full_name,
          phone: additionalData.phone,
          address: additionalData.address,
          username: additionalData.username, // Asegúrate de insertar el username
          security_question: additionalData.security_question,
          security_answer: additionalData.security_answer, // Aquí se guarda la respuesta
        });
  
      if (profileInsertError) throw profileInsertError;
  
      return user;
      
    } catch (err) {
      // Manejamos el error de número telefónico duplicado y cualquier otro error
      if (err.message.includes("El número de teléfono ya fue registrado")) {
        console.error("Error durante el registro:", err.message);
        throw err; // Aquí mostramos el error del teléfono duplicado
      } else {
        console.error('Error durante el registro:', err.message);
        throw err; // Manejamos otros errores
      }
    }
  };
  // Función para iniciar sesión
  const signIn = async (emailOrPhone, password) => {
    setLoading(true);
    try {
      const isPhone = /^\d+$/.test(emailOrPhone); // Verifica si es teléfono
      let email = emailOrPhone;

      if (isPhone) {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('username') // Asegúrate de obtener el username
          .eq('phone', emailOrPhone)
          .single();

        if (profileError || !profile) {
          throw new Error('El número de teléfono no está registrado.');
        }

        email = profile.username; // Usar el correo electrónico (username)
      }

      const { data: { user }, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      const userWithProfile = await fetchUserProfile(user);
      setUser(userWithProfile);
      return userWithProfile;
    } catch (err) {
      console.error('Error durante el inicio de sesión:', err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Función para cerrar sesión
  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser(null);
      window.location.href = '/login';
    } catch (err) {
      console.error('Error durante el cierre de sesión:', err.message);
    }
  };

  // const resetPassword = async (emailOrPhone, newPassword) => {
  //   console.log(`Restableciendo la contraseña para el identificador: ${emailOrPhone} con Nueva contraseña: ${newPassword}.`);
  
  //   // Verificar si es un número de teléfono o correo electrónico
  //   const isPhone = /^\d+$/.test(emailOrPhone); // Si es completamente numérico, lo trataremos como un teléfono
  
  //   let query;
  //   if (isPhone) {
  //     // Si es un teléfono, busca el perfil asociado al número de teléfono
  //     query = supabase
  //       .from('profiles')
  //       .select('username') // Necesitamos el correo electrónico (username)
  //       .eq('phone', emailOrPhone)
  //       .single();
  //   } else {
  //     // Si es un correo electrónico, busca el perfil asociado al correo
  //     query = supabase
  //       .from('profiles')
  //       .select('username') // Seleccionamos el email (username)
  //       .eq('username', emailOrPhone)
  //       .single();
  //   }
  
  //   const { data: profile, error: profileError } = await query;
  
  //   if (profileError || !profile) {
  //     console.error('Error al encontrar el usuario por identificador:', profileError);
  //     throw new Error('Usuario no encontrado.');
  //   }
  
  //   const email = profile.username; // Ahora tenemos el correo asociado
  
  //   // Actualiza la contraseña del usuario usando el correo (username)
  //   const { error: authError } = await supabase.auth.updateUser({
  //     email, // Usamos el email recuperado
  //     password: newPassword, // Nueva contraseña proporcionada
  //   });
  
  //   if (authError) {
  //     console.error('Error al restablecer la contraseña:', authError);
  //     throw authError;
  //   }
  
  //   console.log('¡Contraseña restablecida correctamente!');
  // };

  const resetPassword = async (userId, newPassword) => {
    console.log(`Restableciendo la contraseña para el usuario: ${userId}`);
    const { error } = await supabase.auth.updateUser({
      password: newPassword
    });
  
    if (error) {
      console.error('Error al restablecer la contraseña:', error);
      throw error;
    }
  
    console.log('¡Contraseña restablecida correctamente!');
  };

  const resetPasswordManually = async (userId, newPassword) => {
    console.log(`Restableciendo la contraseña manualmente para el ID de usuario: ${userId}`);
  
    try {
      const { error: authError } = await supabaseAdmin.auth.admin.updateUserById(userId, {
        password: newPassword,
      });
  
      if (authError) {
        console.error('Error al actualizar la contraseña como administrador:', authError);
        throw authError;
      }
  
      console.log('¡Contraseña actualizada correctamente para el ID de usuario:', userId);
    } catch (err) {
      console.error('Error al restablecer la contraseña:', err.message);
      throw err;
    }
  };

  return (
<AuthContext.Provider value={{ user, signIn, signOut, signUp, resetPassword, fetchUserByEmailOrPhone, verifySecurityAnswer, resetPasswordManually }}>
  {loading ? (
    <div className="loading-wrapper">
      <MoonLoader                
          className='loader-wrapper'
          color='#AA0000' /> 
    </div>
  ) : (
    children
  )}
</AuthContext.Provider>
  );
};