// TimeContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
    const [timer, setTimer] = useState(300); // 5 minutos en segundos
    const [cartStarted, setCartStarted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        let interval;
        if (cartStarted) {
            interval = setInterval(() => {
                setTimer(prev => {
                    if (prev === 1) {
                        clearInterval(interval);
                         navigate('/'); // Redirigir al home cuando el tiempo termine

                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [cartStarted]);

    const startTimer = () => {
        setCartStarted(true); // Inicia el temporizador cuando el usuario agrega el primer producto
    };

    const resetTimer = () => {
        setTimer(300); // Reinicia el temporizador a 5 minutos
        setCartStarted(false); // Detiene el temporizador
    };

    return (
        <TimerContext.Provider value={{ timer, startTimer, resetTimer }}>
            {children}
        </TimerContext.Provider>
    );
};